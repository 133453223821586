import { PayPeriodInterval } from "../PayPeriodInterval";
import { WorkShiftSalary } from "../WorkShiftSalary";
import { CzechContract } from "./CzechContract";
import { EmployeeProvider } from "../EmployeeProvider";
import { EmployerProvider } from "../EmployerProvider";
import { CzechSalaryAdvanceProvider } from "./SalaryAdvanceProvider";
import { WorkShiftProvider } from "../WorkShiftProvider";
import { CzechContractType } from "./CzechContractType";
import { WorkBreak } from "../WorkBreak";
import { PayPeriod } from "../PayPeriod";
import { CzechSalaryTax } from "./CzechSalaryTax";
import { UnsupportedContractTypeError } from "../UnsupportedContractTypeError";
import { NotImplementedError } from "../NotImplementedError";
import { CzechSalaryTaxType } from "./CzechSalaryTaxType";
import { CzechDocumentType } from "./CzechDocumentType";
import { CzechSalaryTaxAdjustment } from "./CzechSalaryTaxAdjustment";
import { CzechSalaryTaxAdjustmentType } from "./CzechSalaryTaxAdjustmentType";
import { PaySupplementType } from "../PaySupplementType";
import { CalculatedPaySupplement } from "../CalculatedPaySupplement";
import { InvalidDateIntervalError } from "../InvalidDateIntervalError";
import { InvalidDocumentError } from "../InvalidDocumentError";
import { CzechPeriodSalary } from "./CzechPeriodSalary";
import { CzechAgeCalculator } from "./CzechAgeCalculator";
import { CzechDocumentValidator } from "./CzechDocumentValidator";
import { CzechGrossSalaryIsOverLimitError } from "./CzechGrossSalaryIsOverLimitError";
import { CzechHolidayCalculator } from "./CzechHolidayCalculator";
import { CzechDocumentProvider } from "./CzechDocumentProvider";
import moment, { Moment } from "moment-timezone";
import { CzechContractProvider } from "./CzechContractProvider";
import { DateOutOfDateIntervalError } from "../DateOutOfDateIntervalError";
import { WorkBreakOverlapError } from "../WorkBreakOverlapError";
import { CzechDocument } from "./CzechDocument";
import { getConst } from "../Constants";
import { CzechConstantType } from "./CzechConstantType";
import { formatNumber } from "../utils/FormatNumber";
import { formatPercentageForTexts } from "../utils/FormatPercentageForTexts";
import { WorkShiftCheckFlags } from "../WorkShiftCheckFlags";
import { PaySupplement } from "../PaySupplement";
import { CzechAvailableContract } from "./CzechAvailableContract";
import { WorkShiftCheckLimits } from "../WorkShiftCheckLimits";
import { WorkOffer } from "../WorkOffer";
import { Employee } from "../Employee";
import { ContractValidityType } from "../ContractValidityType";
import { IntervalMatch } from "../IntervalMatch";
import { CzechSalaryType } from "./CzechSalaryType";
import { CzechWorkShiftCheckResult } from "./CzechWorkShiftCheckResult";
import { ContractToSign } from "../ContractToSign";
import { WorkShiftCheckIssue } from "../WorkShiftCheckIssue";
import { ShiftInclusion } from "../ShiftInclusion";
import { Quarter } from "../Quarter";
import { WorkShiftStatus } from "../WorkShiftStatus";
import { WorkShiftType } from "../WorkShiftType";
import { WorkShift } from "../WorkShift";
import { IscoMismatchError } from "../IscoMismatchError";

// do not use the moment constructor directly
// prefer .toISOString(true) over .format()
const timezone = 'Europe/Prague';
const beginningOfTime = '1993-01-01T00:00:00+0100';
function mom(datetime: string): Moment {
  return moment.tz(datetime, timezone);
}

interface ContractTimes {
  startsOn: string;
  endsOn?: string;
  expiresOn: string;
}

interface ContractCheck {
  predicate(contract: CzechContract): boolean;
  flag: WorkShiftCheckFlags;
  description: string;
  extra(contract: CzechContract): string;
}

export class CzechWorkCalculator {
  constructor(
    private readonly employees: EmployeeProvider,
    private readonly employers: EmployerProvider,
    private readonly advances: CzechSalaryAdvanceProvider,
    private readonly shifts: WorkShiftProvider,
    private readonly documents: CzechDocumentProvider,
    private readonly contracts: CzechContractProvider,
  ) {
  }

  /**
   * Vrátí příplatky ke směně podle zadaných pravidel a doby konání směny.
   */
  public static getPaySupplementsForWorkShift(workShift: WorkOffer, paySupplementRules: PaySupplement[]): PaySupplement[] {
    const paySupplements = [];

    const holidayCalculator = new CzechHolidayCalculator(timezone);

    for (const paySupplement of paySupplementRules) {
      if (paySupplement.type === PaySupplementType.Saturday && isWorkShiftDuringSpecialDay(workShift, isDaySaturday)) {
        paySupplements.push(paySupplement);
      }

      if (paySupplement.type === PaySupplementType.Sunday && isWorkShiftDuringSpecialDay(workShift, isDaySunday)) {
              paySupplements.push(paySupplement);
            }

      if (paySupplement.type === PaySupplementType.Night && isWorkShiftDuringNight(workShift)) {
        paySupplements.push(paySupplement);
      }

      if (paySupplement.type === PaySupplementType.Holiday && isWorkShiftDuringSpecialDay(workShift, holidayCalculator.isDayHoliday.bind(holidayCalculator))) {
        paySupplements.push(paySupplement);
      }
    }

    return paySupplements;
  }

  /**
   * Přidá příplatky ke směně podle zadaných pravidel a doby konání směny.
   */
  public static addPaySupplementsToWorkShift(workShift: WorkOffer, paySupplementRules: PaySupplement[]): void {
    workShift.paySupplements = CzechWorkCalculator.getPaySupplementsForWorkShift(workShift, paySupplementRules);
  }

  /**
   * Přidá povinné přestávky. Umí i upravit délku směny podle potvrzeného čistého odpracovaného času.
   */
  public static addRequiredBreakToWorkShift(workShift: WorkOffer, employee: Employee, netMinutes?: number, breakStartsAfterMinutes = 0): void {
    workShift.workBreaks = [];

    const ageOfEmployee = new CzechAgeCalculator().getAgeAtStartOfWorkShift(workShift, employee.dateOfBirth);
    const breakAfter = ageOfEmployee < 18 ? 4.5 * 60 : 6 * 60;
    let breakDuration = 0;

    if (netMinutes !== undefined) {
      const workShiftFinishTimeMoment = mom(workShift.startTime).add(netMinutes, 'minute');
      if (netMinutes > breakAfter) {
        breakDuration = Math.ceil(netMinutes / breakAfter - 1) * 30;
        workShiftFinishTimeMoment.add(breakDuration, 'minute');
      }
      workShift.finishTime = workShiftFinishTimeMoment.toISOString(true);
    }

    const totalShiftDurationIncludingBreaks = calculateTotalShiftDurationIncludingBreaks(workShift);

    if (netMinutes === undefined) {
      breakDuration = Math.ceil(totalShiftDurationIncludingBreaks / breakAfter - 1) * 30;
    }

    if (totalShiftDurationIncludingBreaks > breakAfter) {
      if (breakStartsAfterMinutes <= 0) {
        breakStartsAfterMinutes = Math.ceil((totalShiftDurationIncludingBreaks / 60) / 2) * 60;
      }
      if (breakStartsAfterMinutes > breakAfter) {
        breakStartsAfterMinutes = breakAfter;
      }

      const breakStartTime = mom(workShift.startTime).add(breakStartsAfterMinutes, 'minute');
      const breakEndTime = breakStartTime.clone().add(breakDuration, 'minute');
      workShift.workBreaks.push({
        startsAt: breakStartTime.toISOString(true),
        endsAt: breakEndTime.toISOString(true),
        durationInMinutes: breakDuration,
      });
    }
  }

  /**
   * Zkontroluje základní předpoklady o nabídce práce ve vztahu k nabízené smlouvě.
   */
  public static workShiftInContractOrThrow(workShift: WorkOffer|WorkShift, contract: ContractToSign): void {
    const subject = `${'id' in workShift ? `Work ${workShift.id} from ` : ''}Offer ${workShift.offerId} with contract from Template ${contract.templateId}`;
    const workShiftStartTime = mom(workShift.startTime);
    const contractStartsOn = mom(contract.startsOn);
    const contractExpiresOn = mom(contract.expiresOn);

    if (workShiftStartTime.isBefore(contractStartsOn)) {
      throw new DateOutOfDateIntervalError(subject, workShift.startTime, contract.startsOn, contract.expiresOn);
    }
    if (workShiftStartTime.isAfter(contractExpiresOn)) {
      throw new DateOutOfDateIntervalError(subject, workShift.startTime, contract.startsOn, contract.expiresOn);
    }
    if (!iscoMatch(contract.iscoPrefixes, workShift.iscoCode)) {
      throw new IscoMismatchError(subject, workShift.iscoCode, contract.iscoPrefixes);
    }
  }

  /**
   * Zkontroluje základní předpoklady o objektech směn.
   */
  public static workShiftsValidOrThrow(workShifts: WorkOffer[]): void {
    for (const workShift of workShifts) {
      CzechWorkCalculator.workShiftValidOrThrow(workShift);
    }
  }

  /**
   * Zkontroluje základní předpoklady o objektu směny.
   */
  public static workShiftValidOrThrow(workShift: WorkOffer|WorkShift): void {
    const subject = `${'id' in workShift ? `Work ${workShift.id} from ` : ''}Offer ${workShift.offerId}`;
    const workShiftStartTime = mom(workShift.startTime);
    const workShiftFinishTime = mom(workShift.finishTime);

    if (workShiftFinishTime.isBefore(workShiftStartTime)) {
      throw new InvalidDateIntervalError(subject, workShift.startTime, workShift.finishTime);
    }

    workShift.workBreaks.forEach(workBreak => {
      const workBreakStartsAt = mom(workBreak.startsAt);
      const workBreakEndsAt = mom(workBreak.endsAt);
      const workShiftStartTime = mom(workShift.startTime);
      const workShiftFinishTime = mom(workShift.finishTime);

      if (workBreakStartsAt.isAfter(workBreakEndsAt)) {
        throw new InvalidDateIntervalError(subject, workBreak.startsAt, workBreak.endsAt);
      }
      if (workBreakStartsAt.isBefore(workShiftStartTime)) {
        throw new DateOutOfDateIntervalError(subject, workBreak.startsAt, workShift.startTime, workShift.finishTime);
      }
      if (workBreakStartsAt.isAfter(workShiftFinishTime)) {
        throw new DateOutOfDateIntervalError(subject, workBreak.startsAt, workShift.startTime, workShift.finishTime);
      }
      if (workBreakEndsAt.isBefore(workShiftStartTime)) {
        throw new DateOutOfDateIntervalError(subject, workBreak.endsAt, workShift.startTime, workShift.finishTime);
      }
      if (workBreakEndsAt.isAfter(workShiftFinishTime)) {
        throw new DateOutOfDateIntervalError(subject, workBreak.endsAt, workShift.startTime, workShift.finishTime);
      }

      workShift.workBreaks.forEach(workBreakToCompare => {
        const overlaps = doTimeRangesOverlap(workBreakStartsAt, workBreakEndsAt, mom(workBreakToCompare.startsAt), mom(workBreakToCompare.endsAt));
        if (overlaps && workBreak !== workBreakToCompare) {
          throw new WorkBreakOverlapError(workShift, workBreak, workBreakToCompare);
        }
      });
    });
  }

  /**
   * Zkontroluje základní předpoklady o objektech dokumentů.
   */
  public static documentsValidOrThrow(documents: CzechDocument[]): void {
    for (const document of documents) {
      CzechWorkCalculator.documentValidOrThrow(document);
    }
  }

  /**
   * Zkontroluje základní předpoklady o objektu dokumentu.
   */
  public static documentValidOrThrow(document: CzechDocument): void {
    const subject = `Document ${document.id}`;
    const documentStartsOn = mom(document.startsOn);
    const documentExpiresOn = mom(document.expiresOn);
    if (documentExpiresOn.isBefore(documentStartsOn)) {
      throw new InvalidDateIntervalError(subject, document.startsOn, document.expiresOn);
    }
  }

  /**
   * Zkontroluje základní předpoklady o objektech smluv.
   */
  public static contractsValidOrThrow(contracts: CzechContract[]): void {
    for (const contract of contracts) {
      CzechWorkCalculator.contractValidOrThrow(contract);
    }
  }

  /**
   * Zkontroluje základní předpoklady o objektu smlouvy.
   */
  public static contractValidOrThrow(contract: CzechContract): void {
    const subject = `Contract ${contract.id}`;
    const contractStartsOn = mom(contract.startsOn);
    const contractExpiresOn = mom(contract.expiresOn);
    if (contractExpiresOn.isBefore(contractStartsOn)) {
      throw new InvalidDateIntervalError(subject, contract.startsOn, contract.expiresOn);
    }
    if (contract.endsOn !== undefined) {
      const contractEndsOn = mom(contract.endsOn);
      if (contractExpiresOn.isBefore(contractEndsOn)) {
        throw new InvalidDateIntervalError(subject, contract.endsOn, contract.expiresOn);
      }
    }
  }

  /**
   * Vypočítá časy a hrubou mzdu za směnu.
   */
  public static calculateForWorkShift<Work extends WorkOffer>(workShift: Work): WorkShiftSalary<Work> {
    CzechWorkCalculator.workShiftValidOrThrow(workShift);

    const workTimeTotalMinutes = calculateTotalShiftDurationIncludingBreaks(workShift);
    const workTimeTotalNetMinutes = calculateTotalShiftDurationExcludingBreaks(workShift);
    const workBreaksTotalMinutes = workTimeTotalMinutes - workTimeTotalNetMinutes;

    const paySupplements: CalculatedPaySupplement[] = [];
    for (const paySupplement of workShift.paySupplements) {
      const paySupplementAmount = Math.max(paySupplement.fixedHourlyRate, paySupplement.percentageIncrease / 100 * workShift.hourlyRate);
      let calculatedPaySupplementTimeFrameDurationInMinutes = 0;

      if (paySupplement.type === PaySupplementType.Holiday) {
        const holidayCalculator = new CzechHolidayCalculator(timezone);
        calculatedPaySupplementTimeFrameDurationInMinutes = calculateWorkShiftSpecialTimeFrameDuration(workShift, holidayCalculator.isDayHoliday.bind(holidayCalculator));
      }

      if (paySupplement.type === PaySupplementType.Night) {
        calculatedPaySupplementTimeFrameDurationInMinutes = calculateWorkShiftNightTimeFrameDuration(workShift);
      }

      if (paySupplement.type === PaySupplementType.Saturday) {
        calculatedPaySupplementTimeFrameDurationInMinutes = calculateWorkShiftSpecialTimeFrameDuration(workShift, isDaySaturday);
      }

      if (paySupplement.type === PaySupplementType.Sunday) {
        calculatedPaySupplementTimeFrameDurationInMinutes = calculateWorkShiftSpecialTimeFrameDuration(workShift, isDaySunday);
      }

      const paySupplementApplicableDuration = paySupplement.appliesToEntireShift && calculatedPaySupplementTimeFrameDurationInMinutes > workTimeTotalNetMinutes / 2 ?
        workTimeTotalNetMinutes :
        calculatedPaySupplementTimeFrameDurationInMinutes;
      const calculatedPaySupplement: CalculatedPaySupplement = {
        paySupplement: paySupplement,
        totalGrossSalary: paySupplementAmount * paySupplementApplicableDuration / 60,
        totalMinutes: calculatedPaySupplementTimeFrameDurationInMinutes,
      };
      paySupplements.push(calculatedPaySupplement);
    }

    const workTimeTotalNetHours = workTimeTotalNetMinutes / 60;
    const baseGrossSalary = workShift.hourlyRate * workTimeTotalNetHours;
    const sumOfCalculatedPaySupplementsTotalGrossSalary = paySupplements.reduce((sum: number, paySupplement: CalculatedPaySupplement) => sum + paySupplement.totalGrossSalary, 0);

    return {
      baseGrossSalary,
      paySupplements,
      totalGrossSalary: baseGrossSalary + sumOfCalculatedPaySupplementsTotalGrossSalary,
      workBreaksTotalMinutes,
      workShift,
      workTimeTotalMinutes,
      workTimeTotalNetMinutes,
    };
  }

  /**
   * Vypočítá mzdy a odvody za výplatní období.
   */
  public async calculateSalaries(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    allowBypassSalaryLimitForDpp: boolean,
    allowBypassSalaryLimitForDpc: boolean,
    shiftInclusion: ShiftInclusion.Worked,
  ): Promise<CzechPeriodSalary<WorkShift>[]>;
  public async calculateSalaries(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    allowBypassSalaryLimitForDpp: boolean,
    allowBypassSalaryLimitForDpc: boolean,
    shiftInclusion?: ShiftInclusion,
  ): Promise<CzechPeriodSalary[]>;
  public async calculateSalaries(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    allowBypassSalaryLimitForDpp: boolean,
    allowBypassSalaryLimitForDpc: boolean,
    shiftInclusion = ShiftInclusion.Worked,
  ): Promise<CzechPeriodSalary[]> {
    const dpp = await this.calculateDpp(employeeId, employerId, payInterval, allowBypassSalaryLimitForDpp, shiftInclusion);
    const dpcAndHpp = await this.calculateDpcAndHpp(employeeId, employerId, payInterval, allowBypassSalaryLimitForDpc, shiftInclusion);
    const salaries: CzechPeriodSalary[] = [];
    if (dpp) {
      salaries.push(dpp);
    }
    if (dpcAndHpp) {
      salaries.push(dpcAndHpp);
    }
    return salaries;
  }

  /**
   * Vypočítá mzdu a odvody za DPP za výplatní období.
   */
  public async calculateDpp(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    allowBypassSalaryLimit: boolean,
    shiftInclusion = ShiftInclusion.Worked,
  ): Promise<CzechPeriodSalary|undefined> {
    return this.calculate(employeeId, employerId, payInterval, CzechSalaryType.DPP, [CzechContractType.DohodaOProvedeniPrace], allowBypassSalaryLimit, shiftInclusion);
  }

  /**
   * Vypočítá mzdu a odvody za DPČ a HPP za výplatní období.
   */
  public async calculateDpcAndHpp(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    allowBypassSalaryLimit: boolean,
    shiftInclusion = ShiftInclusion.Worked,
  ): Promise<CzechPeriodSalary|undefined> {
    // order of types is important
    return this.calculate(employeeId, employerId, payInterval, CzechSalaryType.DPC_HPP, [CzechContractType.HlavniPracovniPomer, CzechContractType.DohodaOPracovniCinnosti], allowBypassSalaryLimit, shiftInclusion);
  }

  private async calculate(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    salaryType: CzechSalaryType,
    contractTypes: CzechContractType[],
    allowBypassSalaryLimit: boolean,
    shiftInclusion: ShiftInclusion,
  ): Promise<CzechPeriodSalary|undefined> {
    const endsOn = getEndOfPayPeriodInterval(payInterval).toISOString(true);

    const gross = await this.calculateGross(employeeId, employerId, payInterval, contractTypes, shiftInclusion);
    if (!gross) {
      return;
    }

    const [documents, advances] = await Promise.all([
      this.documents.listForInterval(payInterval.startsOn, endsOn, { employeeId, employerId }),
      this.advances.listForInterval(payInterval, salaryType, { employeeId, employerId }),
    ]);
    CzechWorkCalculator.documentsValidOrThrow(documents);

    const { combinedType, grossSalaries, totalGrossSalary } = gross;
    const { taxes, taxAdjustments, errors } = CzechWorkCalculator.calculateTaxes(totalGrossSalary, payInterval, combinedType, documents, allowBypassSalaryLimit);
    const totalNetSalary = totalGrossSalary - sum(taxes.map((tax) => tax.finalAmount));
    const netSalaryWithheld = CzechWorkCalculator.calculateNetSalaryWithheld(totalGrossSalary, payInterval, combinedType, documents, allowBypassSalaryLimit);
    const minimalNetSalary = Math.min(getConst(CzechConstantType.CISTA_MZDA_PRO_ZADRZENI_ALOKACE, payInterval.startsOn), totalNetSalary);
    const totalNetSalaryAdvance = Math.max(minimalNetSalary, totalNetSalary - netSalaryWithheld);
    const paid = sum(advances.map((adv) => adv.amount));

    return {
      type: salaryType,
      payPeriodInterval: payInterval,
      workShifts: grossSalaries,
      totalGrossSalary,
      taxes,
      taxAdjustments,
      totalNetSalary,
      totalNetSalaryAdvance,
      advances,
      unpaidNetSalary: totalNetSalary - paid,
      unpaidNetSalaryAdvance: totalNetSalaryAdvance - paid,
      errors,
    };
  }

  private async calculateGross(
    employeeId: string,
    employerId: string,
    payInterval: PayPeriodInterval,
    contractTypes: CzechContractType[],
    shiftInclusion: ShiftInclusion,
  ) {
    const endsOn = getEndOfPayPeriodInterval(payInterval).toISOString(true);
    const contracts = await this.contracts.listForInterval(payInterval.startsOn, endsOn, ContractValidityType.Signed, { employeeId, employerId, types: contractTypes });
    CzechWorkCalculator.contractsValidOrThrow(contracts);
    if (contracts.length === 0) {
      return;
    }

    const status = shiftInclusion === ShiftInclusion.PlannedAndWorked ? undefined : WorkShiftStatus.Worked;
    const contractIds = contracts.map(({ id }) => id);
    // checking which contract type is actually signed in order of priority
    const combinedType = contractTypes.find((type) => contracts.some((contract) => contract.type === type))!;

    const shifts = await this.shifts.listForInterval(payInterval.startsOn, endsOn, IntervalMatch.RecordBeginsInRequested, { status, contractIds });
    CzechWorkCalculator.workShiftsValidOrThrow(shifts);
    const grossSalaries = shifts.map(CzechWorkCalculator.calculateForWorkShift);
    const totalGrossSalary = Math.ceil(sum(grossSalaries.map((sal) => sal.totalGrossSalary)));

    return {
      combinedType,
      grossSalaries,
      totalGrossSalary,
    };
  }

  /** Spočte odvody. */
  public static calculateTaxes(
    totalGrossSalary: number,
    payInterval: PayPeriodInterval,
    type: CzechContractType,
    verifiedDocuments: CzechDocument[],
    allowBypassSalaryLimit: boolean,
  ) {
    const errors: Error[] = [];
    const taxAdjustments: CzechSalaryTaxAdjustment[] = [];

    let danZPrijmu: CzechSalaryTax | null = null;

    const zakladniSocialniPojisteni = getConst(CzechConstantType.ZAKLADNI_SOCIALNI_POJISTENI, payInterval.startsOn);
    let socialniPojisteni: CzechSalaryTax | null = {
      type: CzechSalaryTaxType.SocialniPojisteni,
      name: "Sociální pojištění",
      description: `Sociální pojištění ${formatPercentageForTexts(zakladniSocialniPojisteni)} % z vyměřovacího základu.`,
      baseAmount: Math.ceil(totalGrossSalary * zakladniSocialniPojisteni / 100),
      finalAmount: 0,
    };

    const zakladniZdravotniPojisteni = getConst(CzechConstantType.ZAKLADNI_ZDRAVOTNI_POJISTENI, payInterval.startsOn);
    let zdravotniPojisteni: CzechSalaryTax | null = {
      type: CzechSalaryTaxType.ZdravotniPojisteni,
      name: "Zdravotní pojištění",
      description: `Zdravotní pojištění ${formatPercentageForTexts(zakladniZdravotniPojisteni)} % z vyměřovacího základu.`,
      baseAmount: Math.ceil(totalGrossSalary * 0.045),
      finalAmount: 0,
    };

    const contractIsDpp = type === CzechContractType.DohodaOProvedeniPrace;
    const contractIsDpc = type === CzechContractType.DohodaOPracovniCinnosti;
    const contractIsHpp = type === CzechContractType.HlavniPracovniPomer;
    const hasProhlaseniPoplatnika = CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, CzechDocumentType.ProhlaseniPoplatnika);

    if (contractIsDpp) {
      const dppLimit = getConst(CzechConstantType.DPP_LIMIT, payInterval.startsOn);
      const isDppOverLimit = totalGrossSalary > dppLimit;
      if (isDppOverLimit && !allowBypassSalaryLimit) {
        errors.push(new CzechGrossSalaryIsOverLimitError());
      }
      if (isDppOverLimit || hasProhlaseniPoplatnika) {
        const advanceTaxLevel = getConst(CzechConstantType.ZALOHOVA_DAN, payInterval.startsOn);
        danZPrijmu = {
          type: CzechSalaryTaxType.ZalohovaDan,
          name: "Zálohová daň",
          description: `Zálohová daň ${advanceTaxLevel} % z příjmu nad ${formatNumber(dppLimit)} Kč hrubého u DPP.`,
          baseAmount: Math.ceil(Math.ceil(totalGrossSalary / 100) * advanceTaxLevel),
          finalAmount: 0,
        };
      } else {
        const incomeTaxLevel = getConst(CzechConstantType.SRAZKOVA_DAN, payInterval.startsOn);
        danZPrijmu = {
          type: CzechSalaryTaxType.SrazkovaDan,
          name: "Srážková daň",
          description: `Srážková daň ${incomeTaxLevel} % z příjmu do ${formatNumber(dppLimit)} Kč hrubého u DPP.`,
          baseAmount: Math.floor(totalGrossSalary * incomeTaxLevel / 100),
          finalAmount: 0,
        };
      }
      if (!isDppOverLimit) {
        // U DPP se při mzdě do 10000 Kč se neplatí.
        socialniPojisteni = null;
        zdravotniPojisteni = null;
      }
    } else if (contractIsDpc || contractIsHpp) {
      const dpcLimit = getConst(CzechConstantType.DPC_LIMIT, payInterval.startsOn);
      const isDpcOverLimit = totalGrossSalary >= dpcLimit;
      if (contractIsDpc && isDpcOverLimit && !allowBypassSalaryLimit) {
        errors.push(new CzechGrossSalaryIsOverLimitError());
      }
      if (contractIsHpp || contractIsDpc && isDpcOverLimit || hasProhlaseniPoplatnika) {
        const advanceTaxLevel = getConst(CzechConstantType.ZALOHOVA_DAN, payInterval.startsOn);
        const description = contractIsHpp
          ? `Zálohová daň ${advanceTaxLevel} % z příjmu.`
          : `Zálohová daň ${advanceTaxLevel} % z příjmu nad ${formatNumber(dpcLimit)} Kč hrubého u DPČ.`;
        danZPrijmu = {
          type: CzechSalaryTaxType.ZalohovaDan,
          name: "Zálohová daň",
          description,
          baseAmount: Math.ceil(Math.ceil(totalGrossSalary / 100) * advanceTaxLevel),
          finalAmount: 0,
        };
      } else {
        const incomeTaxLevel = getConst(CzechConstantType.SRAZKOVA_DAN, payInterval.startsOn);
        danZPrijmu = {
          type: CzechSalaryTaxType.SrazkovaDan,
          name: "Srážková daň",
          description: `Srážková daň ${incomeTaxLevel} % z příjmu do ${formatNumber(dpcLimit)} Kč hrubého u DPČ.`,
          baseAmount: Math.floor(totalGrossSalary * incomeTaxLevel / 100),
          finalAmount: 0,
        };
      }
      if (contractIsDpc && !isDpcOverLimit) {
        // U DPČ se při mzdě < 4000 Kč se neplatí.
        socialniPojisteni = null;
        zdravotniPojisteni = null;
      }
    } else {
      throw new UnsupportedContractTypeError(type);
    }

    const taxes: CzechSalaryTax[] = [danZPrijmu];

    if (socialniPojisteni) {
      taxes.push(socialniPojisteni);
    }
    if (zdravotniPojisteni) {
      taxes.push(zdravotniPojisteni);
    }

    for (const tax of taxes) { // Nastavení finální hodnoty daně před aplikací odpočtů.
      tax.finalAmount = tax.baseAmount;
    }

    if (zdravotniPojisteni) {
      // Nespravny docasny vypocet
      let minimalniZdravotniPojisteniProZamestnance = this.incorrectMinimalniZdravotniPojisteniProZamestnance(payInterval, totalGrossSalary, verifiedDocuments, errors);

      const zdravotniPojisteniZaplaceneZamestnavatelem = Math.floor(totalGrossSalary * getConst(CzechConstantType.ZDRAVOTNI_POJISTENI_PLACENE_ZAMESTNAVATELEM, payInterval.startsOn) / 100);
      minimalniZdravotniPojisteniProZamestnance -= zdravotniPojisteniZaplaceneZamestnavatelem;
      if (minimalniZdravotniPojisteniProZamestnance > zdravotniPojisteni.baseAmount) {
        taxAdjustments.push({
          type: CzechSalaryTaxAdjustmentType.DoplatekNaMinimalniOdvodZdravotnihoPojisteni,
          name: "Doplatek na minimální odvod zdravotního pojištění",
          description: "TODO",
          appliedToTaxType: zdravotniPojisteni.type,
          totalAmount: minimalniZdravotniPojisteniProZamestnance - zdravotniPojisteni.baseAmount,
        });
        zdravotniPojisteni.finalAmount = minimalniZdravotniPojisteniProZamestnance;
      }
    }

    if (hasProhlaseniPoplatnika) {
      const slevaNaPoplatnika: CzechSalaryTaxAdjustment = {
        type: CzechSalaryTaxAdjustmentType.SlevaNaPoplatnika,
        name: "Prohlášení poplatníka daně z příjmů fyzických osob ze závislé činnosti",
        description: "TODO",
        appliedToTaxType: danZPrijmu.type,
        totalAmount: -getConst(CzechConstantType.SLEVA_NA_POPLATINKA, payInterval.startsOn),
      };
      taxAdjustments.push(slevaNaPoplatnika);
      danZPrijmu.finalAmount += slevaNaPoplatnika.totalAmount;

      if (CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, CzechDocumentType.PotvrzeniOStudiu)) {
        const adjustmentValue = getConst(CzechConstantType.SLEVA_NA_STUDENTA, payInterval.startsOn);
        if (adjustmentValue !== 0) {
          const slevaNaStudenta: CzechSalaryTaxAdjustment = {
            type: CzechSalaryTaxAdjustmentType.SlevaNaStudenta,
            name: "Potvrzení o studiu (pouze do 26 let)",
            description: "TODO",
            appliedToTaxType: danZPrijmu.type,
            totalAmount: -adjustmentValue,
          };
          taxAdjustments.push(slevaNaStudenta);
          danZPrijmu.finalAmount += slevaNaStudenta.totalAmount;
        }
      }

      if (CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, CzechDocumentType.VymerInvalidnihoDuchodu3Stupne)) {
        const slevaNaInvalidniDuchod: CzechSalaryTaxAdjustment = {
          type: CzechSalaryTaxAdjustmentType.SlevaProInvalidniDuchodce,
          name: "Výměr invalidní důchod III. stupně",
          description: "TODO",
          appliedToTaxType: danZPrijmu.type,
          totalAmount: -getConst(CzechConstantType.SLEVA_INVALIDNI_DUCHOD_III, payInterval.startsOn),
        };
        taxAdjustments.push(slevaNaInvalidniDuchod);
        danZPrijmu.finalAmount += slevaNaInvalidniDuchod.totalAmount;
      } else if (CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, CzechDocumentType.VymerInvalidnihoDuchodu12Stupne)) {
        const slevaNaInvalidniDuchod: CzechSalaryTaxAdjustment = {
          type: CzechSalaryTaxAdjustmentType.SlevaProInvalidniDuchodce,
          name: "Výměr invalidní důchod I. a II. stupně",
          description: "TODO",
          appliedToTaxType: danZPrijmu.type,
          totalAmount: -getConst(CzechConstantType.SLEVA_INVALIDNI_DUCHOD, payInterval.startsOn),
        };
        taxAdjustments.push(slevaNaInvalidniDuchod);
        danZPrijmu.finalAmount += slevaNaInvalidniDuchod.totalAmount;
      }

      if (CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, CzechDocumentType.PrukazZTPP)) {
        const slevaNaZTPP: CzechSalaryTaxAdjustment = {
          type: CzechSalaryTaxAdjustmentType.SlevaProZTPP,
          name: "Průkaz ZTP/P",
          description: "TODO",
          appliedToTaxType: danZPrijmu.type,
          totalAmount: -getConst(CzechConstantType.SLEVA_ZTPP, payInterval.startsOn),
        };
        taxAdjustments.push(slevaNaZTPP);
        danZPrijmu.finalAmount += slevaNaZTPP.totalAmount;
      }
    }

    for (const tax of taxes) { // Žádná daň nejde do záporných hodnot.
      tax.finalAmount = Math.max(0, tax.finalAmount);
    }

    return {
      taxes,
      taxAdjustments,
      errors,
    };
  }

  /**
   * Spočte, kolik čisté mzdy musí pracovník dosáhnout, než se mu začnou vyplácet zálohy.
   * Problém je u DPČ, že čistá mzda spadne při překročení rozhodné částky, a pokud by se vyplácela naivně čistá mzda, mohl
   * by si pracovník vyplatit (příklad pro rok 2023) 3.999 Kč, následně odpracovat hodinu, překročit tím rozhodný příjem
   * a Tymbe by pak muselo za něj zaplatit cca 2.500 Kč v odvodech na pojištění.
   */
  public static calculateNetSalaryWithheld(
    totalGrossSalary: number,
    payInterval: PayPeriodInterval,
    type: CzechContractType,
    verifiedDocuments: CzechDocument[],
    allowBypassSalaryLimit: boolean,
  ): number {
    if (CzechContractType.DohodaOPracovniCinnosti !== type || !allowBypassSalaryLimit) {
      return 0;
    }

    const minimalniZdravotniPojisteniProZamestnance = CzechWorkCalculator.incorrectMinimalniZdravotniPojisteniProZamestnance(payInterval, totalGrossSalary, verifiedDocuments, []);
    if (minimalniZdravotniPojisteniProZamestnance === 0) {
      return 0;
    }

    const dpcLimit = getConst(CzechConstantType.DPC_LIMIT, payInterval.startsOn);
    if (totalGrossSalary >= dpcLimit) {
      return 0;
    }

    const salaryIfTaxesAreApplied = CzechWorkCalculator.calculateTaxes(dpcLimit, payInterval, type, verifiedDocuments, allowBypassSalaryLimit);
    const minimalniZdravotniASocialniPojisteni =
      (salaryIfTaxesAreApplied.taxes.find(({ type }) => type === CzechSalaryTaxType.SocialniPojisteni)?.finalAmount ?? 0) +
      (salaryIfTaxesAreApplied.taxes.find(({ type }) => type === CzechSalaryTaxType.ZdravotniPojisteni)?.finalAmount ?? 0);

    return minimalniZdravotniASocialniPojisteni;
  }

  /**
   * Zjistí, zda je možné odpracovat danou směnu.
   */

  public async canEmployeeSignUpForWorkShift(
    employeeId: string,
    workShift: WorkOffer,
    availableContracts: CzechAvailableContract[],
    allowBypassSalaryLimitForDpp: boolean,
    allowBypassSalaryLimitForDpc: boolean,
    allowHpp: boolean,
    ignoreIssues: WorkShiftCheckFlags[] = [],
  ): Promise<CzechWorkShiftCheckResult> {
    try {
      CzechWorkCalculator.workShiftValidOrThrow(workShift);
    } catch (err) {
      return {
        canSignUp: false,
        issues: [new WorkShiftCheckIssue(
          WorkShiftCheckFlags.EXCEPTION,
          err instanceof Error ? err.message : String(err),
          `[${employeeId}][${workShift.offerId}]`,
          err instanceof Error ? err : undefined,
        )],
        flags: [],
      };
    }
    const employee = await this.employees.getById(employeeId);

    const generalResult = await this.canEmployeeSignUpInGeneral(employee, workShift, ignoreIssues);
    if (!generalResult.canSignUp) {
      return generalResult;
    }

    const individualResults: (CzechWorkShiftCheckResult & { type: CzechContractType; availableContract: CzechAvailableContract })[] = [];
    if (availableContracts.length === 0) {
      return {
        canSignUp: false,
        issues: [new WorkShiftCheckIssue(
          WorkShiftCheckFlags.NO_CONTRACTS,
          "Není žádný dostupný pracovní úvazek pro danou pozici.",
          `[${employeeId}][${workShift.offerId}]`,
        )],
        flags: generalResult.flags,
        individualResults,
      };
    }

    // upgrade smlouvy je možný pouze podle této posloupnosti
    const sequence = [ CzechContractType.DohodaOProvedeniPrace, CzechContractType.DohodaOPracovniCinnosti, CzechContractType.HlavniPracovniPomer ];
    availableContracts = [ ...availableContracts ].sort((a, b) => sequence.indexOf(a.type) - sequence.indexOf(b.type));

    let internalError = false;
    const fns = {
      [CzechContractType.DohodaOProvedeniPrace]: ['canEmployeeSignUpOnDpp', allowBypassSalaryLimitForDpp],
      [CzechContractType.DohodaOPracovniCinnosti]: ['canEmployeeSignUpOnDpc', allowBypassSalaryLimitForDpc],
      [CzechContractType.HlavniPracovniPomer]: ['canEmployeeSignUpOnHpp', allowHpp],
    } as const;
    for (const availableContract of availableContracts) {
      const [method, allow] = fns[availableContract.type];
      let internalResult: CzechWorkShiftCheckResult;
      try {
        internalResult = await this[method](employee, workShift, availableContract, allow, ignoreIssues);
      } catch (err) {
        internalError = true;
        internalResult = {
          canSignUp: false,
          issues: [new WorkShiftCheckIssue(
            WorkShiftCheckFlags.EXCEPTION,
            err instanceof Error ? err.message : String(err),
            `[${employeeId}][${workShift.offerId}][${availableContract.templateId}]`,
            err instanceof Error ? err : undefined,
          )],
          flags: [],
        };
      }
      individualResults.push({
        ...internalResult,
        flags: [...generalResult.flags, ...internalResult.flags],
        availableContract,
        type: availableContract.type,
      });
    }

    if (internalError) {
      return {
        canSignUp: false,
        issues: [new WorkShiftCheckIssue(
          WorkShiftCheckFlags.EXCEPTION,
          "Při výpočtu došlo k chybě.",
          `[${employeeId}][${workShift.offerId}][${individualResults.filter((res) => res.issues.some((iss) => iss.flag === WorkShiftCheckFlags.EXCEPTION)).map((res) => res.availableContract.templateId).join(";")}]`,
        )],
        flags: generalResult.flags,
        individualResults,
      };
    }

    for (const individualResult of individualResults) {
      if (individualResult.canSignUp) {
        return {
          ...individualResult,
          individualResults,
        };
      }
    }

    if (individualResults.length === 1) {
      return {
        ...individualResults[0],
        individualResults,
      };
    }

    return {
      canSignUp: false,
      issues: [new WorkShiftCheckIssue(
        WorkShiftCheckFlags.MULTIPLE_DECLINES,
        "Všechny možnosti zamítnuty.",
        `[${employeeId}][${workShift.offerId}][${individualResults.map((res) => `${res.availableContract.templateId}:${res.issues.map((iss) => iss.flag).join(",")}`).join(";")}]`,
      )],
      flags: generalResult.flags,
      individualResults,
    };
  }

  private async canEmployeeSignUpInGeneral(
    employee: Employee,
    workShift: WorkOffer,
    ignoreIssues: WorkShiftCheckFlags[] = [],
  ): Promise<CzechWorkShiftCheckResult> {
    const issues: WorkShiftCheckIssue[] = [];
    const flags: WorkShiftCheckFlags[] = [];

    const employeeDocuments = await this.documents.listForInterval(workShift.startTime, workShift.startTime, { employeeId: employee.id, employerId: workShift.employerId });
    CzechWorkCalculator.documentsValidOrThrow(employeeDocuments);
    const ageOfEmployee = new CzechAgeCalculator().getAgeAtStartOfWorkShift(workShift, employee.dateOfBirth);
    const isAdult = ageOfEmployee >= 18;

    // Kontroly věku
    if (!isAdult) {
      flags.push(WorkShiftCheckFlags.EMPLOYEE_UNDERAGE);
    }
    if (ageOfEmployee < 15) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.EMPLOYEE_UNDER_15,
        "Mladistvý zaměstnanec musí dosáhnout věku 15 let.",
        `[${employee.id}][${workShift.offerId}][${employee.id}]`,
      ));
    }
    if (ageOfEmployee === 15) {
      if (!CzechDocumentValidator.hasVerifiedDocumentOfTypeForWorkShift(employeeDocuments, workShift, CzechDocumentType.PotvrzeniOStudiu)) {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.EMPLOYEE_15_BUT_NOT_STUDYING,
          "Mladistvý zaměstnanec starý 15 let musí mít platné potvrzení o studiu.",
          `[${employee.id}][${workShift.offerId}][${employee.id}]`,
        ));
      }
    }

    // Kontrola zda je zaměstnanec zproštěn zdravotního a sociálního pojištění
    const healthInsuranceExemptingDocuments = [
      CzechDocumentType.PotvrzeniOStudiu,
      CzechDocumentType.VymerStarobnihoDuchodu,
      CzechDocumentType.VymerMaterskeRodicovskeDovolene,
      CzechDocumentType.PotvrzeniJinehoZamestnavateleOOdvoduZdravotnihoPojisteni,
      CzechDocumentType.VymerInvalidnihoDuchodu12Stupne,
      CzechDocumentType.VymerInvalidnihoDuchodu3Stupne,
      CzechDocumentType.PrukazZTPP,
    ];
    if (healthInsuranceExemptingDocuments.some(
      documentType => CzechDocumentValidator.hasVerifiedDocumentOfTypeForWorkShift(employeeDocuments, workShift, documentType)
    )) {
      flags.push(WorkShiftCheckFlags.EXEMPT_FROM_INSURANCE);
    }

    const conflictingShifts = await this.shifts.listForInterval(workShift.startTime, workShift.finishTime, IntervalMatch.RecordOverlapsRequested, { employeeId: employee.id, employerId: workShift.employerId });
    CzechWorkCalculator.workShiftsValidOrThrow(conflictingShifts);
    if (conflictingShifts.length > 0) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.SHIFTS_OVERLAP,
        "Směny u stejného zaměstnavatele se nesmí překrývat.",
        `[${employee.id}][${workShift.offerId}][${conflictingShifts.map((sh) => sh.workId).join(",")}]`,
      ));
    }

    const hourBefore = mom(workShift.startTime).subtract(1, 'hour').toISOString(true);
    const hourAfter = mom(workShift.finishTime).add(1, 'hour').toISOString(true);

    const conflictingShiftsWithinHourInterval = await this.shifts.listForInterval(hourBefore, hourAfter, IntervalMatch.RecordOverlapsRequested, { employeeId: employee.id });
    CzechWorkCalculator.workShiftsValidOrThrow(conflictingShiftsWithinHourInterval);

    const shiftsTooClose = conflictingShiftsWithinHourInterval.filter(({ employerId }) => employerId !== workShift.employerId);
    if (shiftsTooClose.length > 0) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.SHIFTS_TOO_CLOSE,
        "Směny pro různé zaměstnavatele musí mít alespoň hodinový rozestup.",
        `[${employee.id}][${workShift.offerId}][${shiftsTooClose.map((sh) => sh.workId).join(",")}]`,
      ));
    }

    // Mladší 18 let nesmí dále pracovat na směnách, které zasahují do časového úseku 23:00-5:00, tedy mohou na směnu 14:30 - 22:30, ale na 15:00 - 23:30 už nikoliv.
    if (!isAdult) {
      const forbiddenTimes = [
        {
          from: mom(workShift.startTime).startOf('day').hours(23).minutes(0),
          to: mom(workShift.startTime).endOf('day'),
        },
        {
          from: mom(workShift.finishTime).startOf('day'),
          to: mom(workShift.finishTime).startOf('day').hours(5).minutes(0),
        },
      ];

      for (const forbiddenTime of forbiddenTimes) {
        if (doTimeRangesOverlap(forbiddenTime.from, forbiddenTime.to, mom(workShift.startTime), mom(workShift.finishTime))) {
          if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.EMPLOYEE_UNDERAGE_IN_NIGHT_SHIFT)) {
            issues.push(new WorkShiftCheckIssue(
              WorkShiftCheckFlags.EMPLOYEE_UNDERAGE_IN_NIGHT_SHIFT,
              "Osoba mladší 18 let nesmí pracovat v časovém rozmezí 23:00–5:00.",
              `[${employee.id}][${workShift.offerId}][${employee.id}]`,
            ));
          }
        }
      }
    }

    const juvenileDailyNetHoursLimit = 8;
    const adultDailyNetHoursLimit = 12;
    const workShiftDurationExcludingBreaks = calculateTotalShiftDurationExcludingBreaks(workShift);

    // Maximální délka směny je 12 hodin (bez přestávky)
    if (isAdult && workShiftDurationExcludingBreaks > adultDailyNetHoursLimit * 60) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.SHIFT_TOO_LONG,
        `Maximální délka směny je ${adultDailyNetHoursLimit} hodin.`,
        `[${employee.id}][${workShift.offerId}]`,
      ));
    }

    // Maximální délka směny je 12h (bez přestávky) -> 13h je max celkem
    // v případě že směny (u jednoho zaměstnavatele) jsou v rozestupu méně než 30m, berou se jako jedna směna
    const date24HoursBefore = mom(workShift.startTime).subtract(24, 'hour').toISOString(true);
    const date24HoursAfter = mom(workShift.finishTime).add(24, 'hour').toISOString(true);

    const shiftsForEmployerWithin24And24Hours = await this.shifts.listForInterval(date24HoursBefore, date24HoursAfter, IntervalMatch.RecordOverlapsRequested, { employeeId: employee.id, employerId: workShift.employerId });
    CzechWorkCalculator.workShiftsValidOrThrow(shiftsForEmployerWithin24And24Hours);
    const shiftsToCheckDailyLimitSorted = [...shiftsForEmployerWithin24And24Hours, workShift].sort(
      (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );

    const durationOfPauseInWhichShiftsWillBeMergedInMs = 30 * 60 * 1000;
    const maxDurationOfMergedShiftsInMs = 13 * 60 * 60 * 1000;

    let sumOfConcatenatedShifts = 0;
    let lastShiftEndTime: Moment | undefined;
    for (const checkedShift of shiftsToCheckDailyLimitSorted) {
      const checkedShiftStartTime = mom(checkedShift.startTime);
      const checkedShiftEndTime = mom(checkedShift.finishTime);
      if (sumOfConcatenatedShifts === 0 || !lastShiftEndTime) {
        sumOfConcatenatedShifts = checkedShiftEndTime.diff(mom(checkedShift.startTime));
        lastShiftEndTime = checkedShiftEndTime;
        continue;
      }

      if (checkedShiftStartTime.diff(lastShiftEndTime) < durationOfPauseInWhichShiftsWillBeMergedInMs) {
        sumOfConcatenatedShifts += checkedShiftEndTime.diff(lastShiftEndTime);

        if (sumOfConcatenatedShifts > maxDurationOfMergedShiftsInMs) {
          if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK)) {
            issues.push(new WorkShiftCheckIssue(
              WorkShiftCheckFlags.INSUFFICIENT_BREAK,
              `Směna navazuje na jinou přihlášenou směnu a dohromady překračují limit ${adultDailyNetHoursLimit} hodin.`,
              `[${employee.id}][${workShift.offerId}]`,
            ));
          }
        }
        lastShiftEndTime = checkedShiftEndTime;
      } else {
        sumOfConcatenatedShifts = checkedShiftEndTime.diff(mom(checkedShift.startTime));
        lastShiftEndTime = checkedShiftEndTime;
      }
    }

    // Mladší 18 let: Maximální doba práce v jednom dni na vícero směnách nesmí překročit 8 hodin bez přestávky, je tedy možné jít v jednom dni na 2x4h směnu bez přestávky
    // Den je v tomto případě stanoven plovoucím způsobem, tedy jako 24 hodin po sobě jdoucích, nikoliv jako kalendářní den
    if (!isAdult) {
      const dayWorkLimitInMinutes = juvenileDailyNetHoursLimit * 60;
      const shiftsToCheckDailyLimit = [...shiftsForEmployerWithin24And24Hours, workShift];

      for (const checkedShift of shiftsToCheckDailyLimit) {
        const shiftStart = mom(checkedShift.startTime);
        const dayAfterShiftStart = shiftStart.clone().add(24, 'hour');
        const shiftEnd = mom(checkedShift.finishTime);
        const dayBeforeShiftEnds = shiftEnd.clone().subtract(24, 'hour');

        const workWindow24hoursToShiftEnd = shiftsToCheckDailyLimit
          .filter((shift: WorkOffer) => mom(shift.finishTime).isSameOrAfter(shiftStart) && mom(shift.startTime).isSameOrBefore(dayAfterShiftStart))
          .reduce((sum: number, workShiftSignedUpForEmployer: WorkOffer) => sum + calculateTotalShiftDurationExcludingBreaks(workShiftSignedUpForEmployer, shiftStart, dayAfterShiftStart), 0);

        const workWindow24hoursFromShiftStart = shiftsToCheckDailyLimit
          .filter((shift: WorkOffer) => mom(shift.startTime).isSameOrBefore(shiftEnd) && mom(shift.finishTime).isSameOrAfter(dayBeforeShiftEnds))
          .reduce((sum: number, workShiftSignedUpForEmployer: WorkOffer) => sum + calculateTotalShiftDurationExcludingBreaks(workShiftSignedUpForEmployer, dayBeforeShiftEnds, shiftEnd), 0);

        if (workWindow24hoursToShiftEnd > dayWorkLimitInMinutes || workWindow24hoursFromShiftStart > dayWorkLimitInMinutes) {
          if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK)) {
            issues.push(new WorkShiftCheckIssue(
              WorkShiftCheckFlags.INSUFFICIENT_BREAK,
              `Je možné odpracovat pouze ${dayWorkLimitInMinutes / 60} hodin za den.`,
              `[${employee.id}][${workShift.offerId}]`,
            ));
          }
        }
      }
    }

    // Definice časového okna:
    // Pro pauzy nás zajímají pouze časová okna definována takto:
    // Začátek směny + 24h (48h), konec směny - 24h (48h)
    // --
    // Kontrola denních přestávek
    // Pro mladistvé je nutné mít 12 hodinovou přestávku v rámci časového okna, není nutné kontrolovat dva dny
    // U dospělých je možné mít v rámci jednoho okna přestávku pouze 8 hodin (11 je optimum), musí však být splněna podmínka:
    // Suma dvou největších přestávek (větších než 8 hodin) musí být alespoň 22 hodin
    const juvenileMinimalDailyBreak = 12 * 60;
    const adultMinimalDailyBreak = 8 * 60;
    const adultOptimalDailyBreak = 11 * 60;

    const date48HoursBefore = mom(workShift.startTime).subtract(48, 'hour');
    const date48HoursAfter = mom(workShift.finishTime).add(48, 'hour');

    const shiftsForEmployerWithin48And48Hours = await this.shifts.listForInterval(date48HoursBefore.toISOString(true), date48HoursAfter.toISOString(true), IntervalMatch.RecordOverlapsRequested, { employeeId: employee.id, employerId: workShift.employerId });
    CzechWorkCalculator.workShiftsValidOrThrow(shiftsForEmployerWithin48And48Hours);
    const shiftsToCheckDailyBreaks = [...shiftsForEmployerWithin48And48Hours, workShift];

    const breakList = getAllBreaks(shiftsToCheckDailyBreaks, date48HoursBefore, date48HoursAfter);
    const minimumBreak = isAdult ? adultMinimalDailyBreak : juvenileMinimalDailyBreak;

    for (const checkedShift of shiftsToCheckDailyBreaks) {
      const shiftStart = mom(checkedShift.startTime);
      const shiftEnd = mom(checkedShift.finishTime);

      // Kontrola jestli je správná pauza v následujících 24 hodinách, případně 48
      if (mom(checkedShift.startTime).isSameOrBefore(mom(workShift.startTime))) {
        const dayAfterShiftStart = shiftStart.clone().add(24, 'hour');
        const longestBreak = getLongestBreaks(breakList, shiftStart, dayAfterShiftStart, 1).pop();

        if (!longestBreak || longestBreak < minimumBreak) {
          if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK)) {
            issues.push(new WorkShiftCheckIssue(
              WorkShiftCheckFlags.INSUFFICIENT_BREAK,
              `Není dodržena doba nepřetržitého denního odpočinku alespoň ${minimumBreak / 60} hodin/den.`,
              `[${employee.id}][${workShift.offerId}]`,
            ));
          }
        } else if (isAdult && longestBreak < adultOptimalDailyBreak) {
          const twoDaysAfterShiftStart = shiftStart.clone().add(48, 'hour');
          const sumOfTwoLongestBreaks = getLongestBreaks(breakList, shiftStart, twoDaysAfterShiftStart, 2).reduce((total, pauseLength) => total + pauseLength, 0);

          if (sumOfTwoLongestBreaks < adultOptimalDailyBreak * 2) {
            if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK)) {
              issues.push(new WorkShiftCheckIssue(
                WorkShiftCheckFlags.INSUFFICIENT_BREAK,
                `Není dodržena doba nepřetržitého denního odpočinku alespoň ${adultOptimalDailyBreak / 60} hodin/den prodlouženého o zkrácení předešlého odpočinku.`,
                `[${employee.id}][${workShift.offerId}]`,
              ));
            }
          }
        }
      }

      // Kontrola jestli je správná pauza v předchozích 24 hodinnách, případně 48
      if (mom(checkedShift.finishTime).isSameOrAfter(mom(workShift.finishTime))) {
        const dayBeforeShiftEnds = shiftEnd.clone().subtract(24, 'hour');
        const longestBreak = getLongestBreaks(breakList, dayBeforeShiftEnds, shiftEnd, 1).pop();

        if (!longestBreak || longestBreak < minimumBreak) {
          if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK)) {
            issues.push(new WorkShiftCheckIssue(
              WorkShiftCheckFlags.INSUFFICIENT_BREAK,
              `Není dodržena doba nepřetržitého denního odpočinku alespoň ${minimumBreak / 60} hodin/den.`,
              `[${employee.id}][${workShift.offerId}]`,
            ));
          }
        } else if (isAdult && longestBreak < adultOptimalDailyBreak) {
          const twoDaysBeforeShiftEnds = shiftEnd.clone().subtract(48, 'hour');
          const sumOfTwoLongestBreaks = getLongestBreaks(breakList, twoDaysBeforeShiftEnds, shiftEnd, 2).reduce((total, pauseLength) => total + pauseLength, 0);

          if (sumOfTwoLongestBreaks < adultOptimalDailyBreak * 2) {
            if (!issues.some(({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK)) {
              issues.push(new WorkShiftCheckIssue(
                WorkShiftCheckFlags.INSUFFICIENT_BREAK,
                `Není dodržena doba nepřetržitého denního odpočinku alespoň ${adultOptimalDailyBreak / 60} hodin/den prodlouženého o zkrácení předešlého odpočinku.`,
                `[${employee.id}][${workShift.offerId}]`,
              ));
            }
          }
        }
      }
    }

    // Definice časového okna:
    // Pro pauzy nás zajímají pouze časová okna definována takto:
    // Začátek směny + 7 dní (14 dní), konec směny - 7 dní (14 dní)
    // --
    // Kontrola denních přestávek
    // U mladistvých je možné mít v rámci 7 dní okna přestávku 48 hodin.
    //
    // U dospělých je možné mít v rámci 7 dní okna přestávku 24 hodin.
    // OR
    // U dospělých je možné mít v rámci 14 dní okna přestávku 70 hodin.

    const juvenileMinimalWeeklyBreak = 48 * 60;
    const adultMinimalWeeklyBreak = 24 * 60;
    const adultMinimalTwoWeeksBreak = 70 * 60;

    // get borders for two weeks in the future and two weeks in the past
    const dateTwoWeeksBefore = mom(workShift.startTime).subtract(14, 'days');
    const dateTwoWeeksAfter = mom(workShift.finishTime).add(14, 'days');
    // get all the shifts in this scope +-14 days
    const shiftsForEmployerWithinTwoWeeksAferAndBeforeShift =await this.shifts.listForInterval(dateTwoWeeksBefore.toISOString(true),dateTwoWeeksAfter.toISOString(true),IntervalMatch.RecordOverlapsRequested,{ employeeId: employee.id, employerId: workShift.employerId });
    // validate all the shifts
    CzechWorkCalculator.workShiftsValidOrThrow(shiftsForEmployerWithinTwoWeeksAferAndBeforeShift);
    // pack up shifts from the scope plus wanted shift
    const shiftsToCheckTwoWeeklsBreaks = [...shiftsForEmployerWithinTwoWeeksAferAndBeforeShift,workShift];
    // get all the breaks between shifts in the +-14 days scope
    const breakListForTwoWeeks = getAllBreaks(shiftsToCheckTwoWeeklsBreaks,dateTwoWeeksBefore,dateTwoWeeksAfter);
    // two weeks end

    // set minimum weekly hour rest rate based on the isAdult paramter
    const minimumWeeklyBreak = isAdult
      ? adultMinimalWeeklyBreak
      : juvenileMinimalWeeklyBreak;

    // iterate through two week region
    for (const checkedShift of shiftsToCheckTwoWeeklsBreaks) {
      const shiftStart = mom(checkedShift.startTime);
      const shiftEnd = mom(checkedShift.finishTime);
      // check if the shift contains contiuous rest interval of at least 24 hours to the +7 days
      if (mom(checkedShift.startTime).isSameOrBefore(mom(workShift.startTime))) {
        const weekAfterShiftStart = shiftStart.clone().add(7, 'days');
        // get longest break for the one week interval with limts set by iterated shift start and (week shift start +1 week)
        const longestBreak = getLongestBreaks(breakListForTwoWeeks,shiftStart,weekAfterShiftStart,1).pop();
        // if there are no breaks within the region, or the breaks are not at least 24 hours, return failure
        if (!longestBreak || longestBreak < minimumWeeklyBreak) {
          if (
            !issues.some(
              ({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK
            )
          ) {
            issues.push(
              new WorkShiftCheckIssue(
                WorkShiftCheckFlags.INSUFFICIENT_BREAK,
                `Není dodržena doba nepřetržitého denního odpočinku alespoň ${
                  minimumWeeklyBreak / 60
                } hodin/týden.`,
                `[${employee.id}][${workShift.offerId}]`
              )
            );
          }
          // else, if there are breaks bigger then one week minimum but lower than two weeks minimum, check if the sum of 2 biggest rests is 70 hours
        } else if (isAdult && longestBreak < adultMinimalTwoWeeksBreak) {
          const twoWeeksAfterShiftStart = shiftStart.clone().add(14, 'days');
          const sumOfTwoLongestBreaks = getLongestBreaks(breakListForTwoWeeks,shiftStart,twoWeeksAfterShiftStart,2).reduce((total, pauseLength) => total + pauseLength, 0);
          if (sumOfTwoLongestBreaks < adultMinimalTwoWeeksBreak) {
            if (
              !issues.some(
                ({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK
              )
            ) {
              issues.push(
                new WorkShiftCheckIssue(
                  WorkShiftCheckFlags.INSUFFICIENT_BREAK,
                  `Není dodržena doba nepřetržitého denního odpočinku alespoň ${
                    adultMinimalTwoWeeksBreak / 60
                  } hodin/14 dní prodlouženého o zkrácení předešlého odpočinku.`,
                  `[${employee.id}][${workShift.offerId}]`
                )
              );
            }
          }
        }

        // check if the shift contains contiuous rest interval of at least 24 hours to the -7 days
        if (mom(checkedShift.finishTime).isSameOrAfter(mom(workShift.finishTime))) {
          const weekBeforeShiftEnds = shiftEnd.clone().subtract(7, 'days');

          const longestBreak = getLongestBreaks(breakListForTwoWeeks,weekBeforeShiftEnds,shiftEnd,1).pop();

          if (!longestBreak || longestBreak < minimumWeeklyBreak) {
            if (
              !issues.some(
                ({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK
              )
            ) {
              issues.push(
                new WorkShiftCheckIssue(
                  WorkShiftCheckFlags.INSUFFICIENT_BREAK,
                  `Není dodržena doba nepřetržitého denního odpočinku alespoň ${
                    minimumWeeklyBreak / 60
                  } hodin/týden.`,
                  `[${employee.id}][${workShift.offerId}]`
                )
              );
            }
          } else if (isAdult && longestBreak < adultMinimalTwoWeeksBreak) {
            const twoWeeksBeforeShiftEnd = shiftEnd.clone().subtract(14, 'days');
            const sumOfTwoLongestBreaks = getLongestBreaks(breakListForTwoWeeks,twoWeeksBeforeShiftEnd,shiftEnd,2).reduce((total, pauseLength) => total + pauseLength, 0);
            if (sumOfTwoLongestBreaks < adultMinimalTwoWeeksBreak) {

              if (
                !issues.some(
                  ({ flag }) => flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK
                )
              ) {
                issues.push(
                  new WorkShiftCheckIssue(
                    WorkShiftCheckFlags.INSUFFICIENT_BREAK,
                    `Není dodržena doba nepřetržitého denního odpočinku alespoň ${
                      adultMinimalTwoWeeksBreak / 60
                    } hodin/14 dní prodlouženého o zkrácení předešlého odpočinku.`,
                    `[${employee.id}][${workShift.offerId}]`
                  )
                );
              }
            }
          }
        }
      }
    }

    splitIgnoredIssues(issues, flags, ignoreIssues);
    return {
      canSignUp: issues.length === 0,
      issues,
      flags,
    };
  }

  private async canEmployeeSignUpOnDpp(
    employee: Employee,
    workShift: WorkOffer,
    availableContract: CzechAvailableContract,
    allowBypassSalaryLimit: boolean,
    ignoreIssues: WorkShiftCheckFlags[] = [],
  ): Promise<CzechWorkShiftCheckResult> {
    const shiftCalculation = CzechWorkCalculator.calculateForWorkShift(workShift);
    const payInterval = CzechWorkCalculator.getPayIntervalForWorkShift(workShift);
    const totalsWithout = await this.calculateGross(employee.id, workShift.employerId, payInterval, [CzechContractType.DohodaOProvedeniPrace], ShiftInclusion.PlannedAndWorked);
    const totalGrossWithout = totalsWithout?.totalGrossSalary ?? 0;

    const startOfYear = mom(payInterval.startsOn).startOf('year').toISOString(true);
    const endOfYear = mom(payInterval.startsOn).endOf('year').toISOString(true);
    const allDppsInYear = await this.contracts.listForInterval(startOfYear, endOfYear, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOProvedeniPrace] });
    CzechWorkCalculator.contractsValidOrThrow(allDppsInYear);
    const otherShiftsInYear = await this.shifts.listForInterval(startOfYear, endOfYear, IntervalMatch.RecordBeginsInRequested, { contractIds: allDppsInYear.map((contract) => contract.id), type: WorkShiftType.Regular });
    CzechWorkCalculator.workShiftsValidOrThrow(otherShiftsInYear);
    const calculationsInYear = otherShiftsInYear.map(CzechWorkCalculator.calculateForWorkShift);
    const totalNetMinutesWithout = sum(calculationsInYear.map((sal) => sal.workTimeTotalNetMinutes));

    const limits = {
      allowBypassSalaryLimit,
      maxWorkTimeForEntireContractInMinutes: 300 * 60, // 300 hodin v kalendářním roce,
      totalWorkTimeForEntireContractInMinutes: totalNetMinutesWithout + (workShift.type === WorkShiftType.Vacation ? 0 : shiftCalculation.workTimeTotalNetMinutes),
      maxGrossMonthlySalary: getConst(CzechConstantType.DPP_LIMIT, payInterval.startsOn), // 10.000,- Kč/měsíc
      totalGrossSalaryThisMonth: totalGrossWithout + shiftCalculation.totalGrossSalary,
    } satisfies WorkShiftCheckLimits;

    const issues: WorkShiftCheckIssue[] = [];
    const flags: WorkShiftCheckFlags[] = [];

    if (limits.totalWorkTimeForEntireContractInMinutes > limits.maxWorkTimeForEntireContractInMinutes) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.TIME_LIMIT_EXCEEDED,
        "Směny za daný kalendářní rok by překročily limit 300 hodin.",
        `[${employee.id}][${workShift.offerId}]`,
      ));
    }

    if (limits.totalGrossSalaryThisMonth > limits.maxGrossMonthlySalary) {
      if (totalGrossWithout <= limits.maxGrossMonthlySalary) {
        flags.push(WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED_NOW);
      }
      if (allowBypassSalaryLimit) {
        flags.push(WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED);
      } else {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED,
          `Nelze překročit částku ${formatNumber(limits.maxGrossMonthlySalary)},- Kč za měsíc.`,
          `[${employee.id}]`,
        ));
      }
    }

    // we used to check if all the info stayed the same since signing and potentially sign a new contract
    // right now we only check select critical data (should be solved with contract amending)
    const extraChecks: ContractCheck[] = [];
    const signedContracts = await this.contracts.listForInterval(workShift.startTime, workShift.startTime, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOProvedeniPrace] });
    CzechWorkCalculator.contractsValidOrThrow(signedContracts);
    const signedContract = CzechWorkCalculator.matchContract(employee, workShift, availableContract, signedContracts, extraChecks, false, issues, flags, ignoreIssues);

    let sign: ContractToSign|undefined;
    if (!signedContract) {
      sign = signAvailable(availableContract);
      CzechWorkCalculator.workShiftInContractOrThrow(workShift, sign);
      // we don't mind any kind of overlap, just use the proposed validity
    }
    const resultingContract: ContractTimes = (signedContract ?? sign)!;

    // working on DPP after DPČ/HPP work has started would be considered tax evasion
    const higherOverlappingContracts = (await this.contracts.listForInterval(resultingContract.startsOn, resultingContract.expiresOn, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti, CzechContractType.HlavniPracovniPomer] }))
      .filter((contract) => iscoMatch(contract.iscoPrefixes, workShift.iscoCode));
    CzechWorkCalculator.contractsValidOrThrow(higherOverlappingContracts);
    const shiftsForHigherContractsBefore = await this.shifts.listForInterval(resultingContract.startsOn, workShift.startTime, IntervalMatch.RecordBeginsInRequested, { contractIds: higherOverlappingContracts.map((contract) => contract.id) });
    CzechWorkCalculator.workShiftsValidOrThrow(shiftsForHigherContractsBefore);
    if (shiftsForHigherContractsBefore.length > 0) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.CONTRACTS_DOWNGRADE,
        "Již jsou přihlášené dřívejší směny na DPČ nebo HPP se stejným zaměstnavatelem na stejnou pozici v době platnosti této DPP.",
        `[${employee.id}][${workShift.offerId}][${shiftsForHigherContractsBefore.map((sh) => sh.workId).join(",")}]`,
      ));
    }

    splitIgnoredIssues(issues, flags, ignoreIssues);
    if (issues.length > 0) {
      return {
        canSignUp: false,
        signedContract,
        issues,
        flags,
        limits,
      };
    }

    return {
      canSignUp: true,
      signedContract,
      makeContractChanges: sign ? { sign } : undefined,
      issues,
      flags,
      limits,
    };
  }

  private async canEmployeeSignUpOnDpc(
    employee: Employee,
    workShift: WorkOffer,
    availableContract: CzechAvailableContract,
    allowBypassSalaryLimit: boolean,
    ignoreIssues: WorkShiftCheckFlags[] = [],
  ): Promise<CzechWorkShiftCheckResult> {
    const shiftCalculation = CzechWorkCalculator.calculateForWorkShift(workShift);
    const payInterval = CzechWorkCalculator.getPayIntervalForWorkShift(workShift);
    const totalsWithout = await this.calculateGross(employee.id, workShift.employerId, payInterval, [CzechContractType.DohodaOPracovniCinnosti, CzechContractType.HlavniPracovniPomer], ShiftInclusion.PlannedAndWorked);
    const totalGrossWithout = totalsWithout?.totalGrossSalary ?? 0;

    const issues: WorkShiftCheckIssue[] = [];
    const flags: WorkShiftCheckFlags[] = [];

    // we don't check if all the info stayed the same since signing because we don't know how to amend contracts anyway
    // we don't care about the select extra data, they're not on the contract itself
    const extraChecks: ContractCheck[] = [];
    const signedContracts = await this.contracts.listForInterval(workShift.startTime, workShift.startTime, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti] });
    CzechWorkCalculator.contractsValidOrThrow(signedContracts);
    const signedContract = CzechWorkCalculator.matchContract(employee, workShift, availableContract, signedContracts, extraChecks, true, issues, flags, ignoreIssues);

    let sign: ContractToSign|undefined;
    if (!signedContract) {
      const endOfDayBeforeWork = mom(workShift.startTime).subtract(1, 'day').endOf('day').toISOString(true);

      sign = signAvailable(availableContract);
      CzechWorkCalculator.workShiftInContractOrThrow(workShift, sign);

      // try to keep minimum of a day between DPČs by postponing contract start (up to the day of the shift)
      // we usually get startsOn set to today, but we don't want to fail the sign up just because of that
      // effective DPČ validity applies here, so we have to load by signed validity and then filter manually
      const signedDpcsToAvoid = (await Promise.all((await this.contracts.listForInterval(subDay(availableContract.startsOn), endOfDayBeforeWork, ContractValidityType.Signed, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti] }))
        .filter((contract) => iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes))
        .map((contract) => this.computeDpcDates(contract))))
        .filter((contract) => mom(contract.dpcEffectiveEndsOn).isSameOrAfter(mom(subDay(availableContract.startsOn))));
      CzechWorkCalculator.contractsValidOrThrow(signedDpcsToAvoid);
      for (const signedDpcToAvoid of signedDpcsToAvoid) {
        const shiftTo = moment.min(mom(addDay(signedDpcToAvoid.dpcEffectiveEndsOn)), mom(endOfDayBeforeWork));
        const shiftByDays = Math.ceil(Math.max(0, shiftTo.diff(mom(sign.startsOn), 'days', true)));
        const startsOn = mom(sign.startsOn).add(shiftByDays, 'day').toISOString(true);
        sign = signAvailable({ ...availableContract, startsOn });
        CzechWorkCalculator.workShiftInContractOrThrow(workShift, sign);
      }

      // try to avoid overlap with HPPs by postponing contract start (up to the day of the shift)
      // we usually get startsOn set to today, but we don't want to fail the sign up just because of that
      // TD-2448: keep this to avoid downgrades
      const signedHppsToAvoid = (await this.contracts.listForInterval(availableContract.startsOn, endOfDayBeforeWork, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.HlavniPracovniPomer] }))
        .filter((contract) => iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes));
      CzechWorkCalculator.contractsValidOrThrow(signedHppsToAvoid);
      for (const signedHppToAvoid of signedHppsToAvoid) {
        const shiftTo = moment.min(mom(signedHppToAvoid.endsOn), mom(endOfDayBeforeWork));
        const shiftByDays = Math.ceil(Math.max(0, shiftTo.diff(mom(sign.startsOn), 'days', true)));
        const startsOn = mom(sign.startsOn).add(shiftByDays, 'day').toISOString(true);
        sign = signAvailable({ ...availableContract, startsOn });
        CzechWorkCalculator.workShiftInContractOrThrow(workShift, sign);
      }

      // final check for DPČs, minimum a day in between
      // effective DPČ validity applies here, so we have to load by signed validity and then filter manually
      const conflictingSignedDpcs = (await Promise.all((await this.contracts.listForInterval(subDay(sign.startsOn), addDay(sign.expiresOn), ContractValidityType.Signed, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti] }))
        .filter((contract) => iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes))
        .map((contract) => this.computeDpcDates(contract))))
        .filter((contract) => mom(contract.dpcEffectiveEndsOn).isSameOrAfter(mom(subDay(sign!.startsOn))));
      CzechWorkCalculator.contractsValidOrThrow(conflictingSignedDpcs);
      const [ overlappingSignedDpcs, signedDpcsViolatingBreak ] = split(conflictingSignedDpcs, (contract) => mom(contract.dpcEffectiveEndsOn).isSameOrAfter(mom(sign!.startsOn)) && mom(contract.startsOn).isSameOrBefore(mom(sign!.expiresOn)));
      if (overlappingSignedDpcs.length > 0) {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACTS_OVERLAP,
          "Není podepsaná DPČ pro danou směnu, ale novou nelze podepsat, protože by se překryla s jinou existující DPČ se stejným zaměstnavatelem na stejnou pozici.",
          `[${employee.id}][${workShift.offerId}][${overlappingSignedDpcs.map((con) => con.id).join(",")}]`,
        ));
      }
      if (signedDpcsViolatingBreak.length > 0) {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACTS_BREAK_OVERLAP,
          "Není podepsaná DPČ pro danou směnu, ale novou nelze podepsat, protože by neměla volný den od jiné existující DPČ se stejným zaměstnavatelem na stejnou pozici.",
          `[${employee.id}][${workShift.offerId}][${signedDpcsViolatingBreak.map((con) => con.id).join(",")}]`,
        ));
      }

      // final check for HPPs, overlaps are not allowed
      // TD-2448: keep this to avoid downgrades
      const conflictingSignedHpps = (await this.contracts.listForInterval(sign.startsOn, sign.expiresOn, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.HlavniPracovniPomer] }))
        .filter((contract) => iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes));
      CzechWorkCalculator.contractsValidOrThrow(conflictingSignedHpps);
      if (conflictingSignedHpps.length > 0) {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACTS_OVERLAP,
          "Není podepsaná DPČ pro danou směnu, ale novou nelze podepsat, protože by se překryla s jinou existující HPP se stejným zaměstnavatelem na stejnou pozici.",
          `[${employee.id}][${workShift.offerId}][${conflictingSignedHpps.map((con) => con.id).join(",")}]`,
        ));
      }
    }
    const resultingContract: ContractTimes = (signedContract ?? sign)!;
    // count whole weeks in the contract, maximum is 52
    const weeksInContract = Math.min(52, Math.floor(mom(resultingContract.expiresOn).add(1, 'second').diff(mom(resultingContract.startsOn), 'day') / 7));

    const otherShiftsOnContract = signedContract ? await this.shifts.listForInterval(signedContract.startsOn, signedContract.expiresOn, IntervalMatch.RecordBeginsInRequested, { contractIds: [ signedContract.id ] }) : [];
    CzechWorkCalculator.workShiftsValidOrThrow(otherShiftsOnContract);
    const calculationsOnContract = otherShiftsOnContract.map(CzechWorkCalculator.calculateForWorkShift);
    const totalNetMinutesWithout = sum(calculationsOnContract.map((sal) => sal.workTimeTotalNetMinutes));

    const limits = {
      allowBypassSalaryLimit,
      maxWorkTimeForEntireContractInMinutes: weeksInContract * 20 * 60, // max v průměru 20h/týdně
      totalWorkTimeForEntireContractInMinutes: totalNetMinutesWithout + shiftCalculation.workTimeTotalNetMinutes,
      maxGrossMonthlySalary: getConst(CzechConstantType.DPC_LIMIT, payInterval.startsOn), // 4.000,- Kč/měsíc
      totalGrossSalaryThisMonth: totalGrossWithout + shiftCalculation.totalGrossSalary,
    } satisfies WorkShiftCheckLimits;

    if (limits.totalWorkTimeForEntireContractInMinutes > limits.maxWorkTimeForEntireContractInMinutes) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.TIME_LIMIT_EXCEEDED,
        "Nelze překročit průměr odpracovaných 20 hodin za týden.",
        `[${employee.id}][${workShift.offerId}]`,
      ));
    }

    if (limits.totalGrossSalaryThisMonth > limits.maxGrossMonthlySalary) {
      if (totalGrossWithout <= limits.maxGrossMonthlySalary) {
        flags.push(WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED_NOW);
      }
      if (allowBypassSalaryLimit) {
        flags.push(WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED);
      } else {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED,
          `Nelze překročit částku ${formatNumber(limits.maxGrossMonthlySalary)},- Kč za měsíc.`,
          `[${employee.id}][${workShift.offerId}]`,
        ));
      }
    }

    // working on DPP after DPČ/HPP work has started would be considered tax evasion
    const overlappingDpps = (await this.contracts.listForInterval(resultingContract.startsOn, resultingContract.expiresOn, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOProvedeniPrace] }))
      .filter((contract) => iscoMatch(contract.iscoPrefixes, workShift.iscoCode));
    CzechWorkCalculator.contractsValidOrThrow(overlappingDpps);
    const shiftsForDppsAfter = await this.shifts.listForInterval(workShift.startTime, resultingContract.expiresOn, IntervalMatch.RecordBeginsInRequested, { contractIds: overlappingDpps.map((contract) => contract.id) });
    CzechWorkCalculator.workShiftsValidOrThrow(shiftsForDppsAfter);
    if (shiftsForDppsAfter.length > 0) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.CONTRACTS_DOWNGRADE,
        "Již jsou přihlášené pozdější směny na DPP se stejným zaměstnavatelem na stejnou pozici v době platnosti této DPČ.",
        `[${employee.id}][${workShift.offerId}][${shiftsForDppsAfter.map((sh) => sh.workId).join(",")}]`,
      ));
    }

    splitIgnoredIssues(issues, flags, ignoreIssues);
    if (issues.length > 0) {
      return {
        canSignUp: false,
        signedContract,
        issues,
        flags,
        limits,
      };
    }

    return {
      canSignUp: true,
      signedContract,
      makeContractChanges: sign ? { sign } : undefined,
      issues,
      flags,
      limits,
    };
  }

  private async canEmployeeSignUpOnHpp(
    employee: Employee,
    workShift: WorkOffer,
    availableContract: CzechAvailableContract,
    allowSignUp: boolean,
    ignoreIssues: WorkShiftCheckFlags[] = [],
  ): Promise<CzechWorkShiftCheckResult> {
    const issues: WorkShiftCheckIssue[] = [];
    const flags: WorkShiftCheckFlags[] = [];

    // we don't check if all the info stayed the same since signing because we don't know how to amend contracts anyway
    // we don't care about the select extra data, they're not on the contract itself
    const extraChecks: ContractCheck[] = [];
    const signedContracts = await this.contracts.listForInterval(workShift.startTime, workShift.startTime, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.HlavniPracovniPomer] });
    CzechWorkCalculator.contractsValidOrThrow(signedContracts);
    const signedContract = CzechWorkCalculator.matchContract(employee, workShift, availableContract, signedContracts, extraChecks, true, issues, flags, ignoreIssues);

    let shorten: CzechContract|undefined;
    let sign: ContractToSign|undefined;


    if (!signedContract) {
      const endOfDayBeforeWork = mom(workShift.startTime).subtract(1, 'day').endOf('day').toISOString(true);
      const startOfDayOfWork = mom(workShift.startTime).startOf('day').toISOString(true);

      sign = signAvailable(availableContract);
      CzechWorkCalculator.workShiftInContractOrThrow(workShift, sign);

      // if there's a DPČ that would overlap with our new HPP, cut it short just before this shift
      const signedDpcsToShorten = (await this.contracts.listForInterval(workShift.startTime, workShift.startTime, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti] }))
        .filter((contract) => iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes));
      CzechWorkCalculator.contractsValidOrThrow(signedDpcsToShorten);
      if (signedDpcsToShorten.length > 1) {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACTS_OVERLAP,
          "Existuje více DPČ se stejným zaměstnavatelem na stejnou pozici.",
          `[${employee.id}][${workShift.offerId}][${signedDpcsToShorten.map((con) => con.id).join(",")}]`,
        ));
      } else if (signedDpcsToShorten.length === 1) {
        const [signedDpcToShorten] = signedDpcsToShorten;
        // we must not leave any shifts outside the shortened validity
        const shiftsThatWouldBeAbandoned = await this.shifts.listForInterval(startOfDayOfWork, signedDpcToShorten.endsOn, IntervalMatch.RecordBeginsInRequested, { contractIds: [signedDpcToShorten.id] });
        if (shiftsThatWouldBeAbandoned.length === 0) {
          shorten = structuredClone(signedDpcToShorten);
          shorten.endsOn = endOfDayBeforeWork;
          CzechWorkCalculator.contractValidOrThrow(shorten);
        }
      }
      shorten = undefined; // TD-2448: shortening is disabled for now

      // try to avoid overlap by postponing contract start (up to the day of the shift)
      // we usually get startsOn set to today, but we don't want to fail the sign up just because of that
      // especially if we just shortened a DPČ (then HPP needs to start on the day of the shift)
      const signedContractsToAvoid = (await this.contracts.listForInterval(availableContract.startsOn, endOfDayBeforeWork, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti, CzechContractType.HlavniPracovniPomer] }))
        .filter((contract) => contract.type !== CzechContractType.DohodaOPracovniCinnosti || iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes))
        .filter((contract) => contract.id !== shorten?.id);
      CzechWorkCalculator.contractsValidOrThrow(signedContractsToAvoid);
      if (shorten) {
        signedContractsToAvoid.push(shorten);
      }
      for (const signedContractToAvoid of signedContractsToAvoid) {
        const shiftTo = moment.min(mom(signedContractToAvoid.endsOn), mom(endOfDayBeforeWork));
        const shiftByDays = Math.ceil(Math.max(0, shiftTo.diff(mom(sign.startsOn), 'days', true)));
        const startsOn = mom(sign.startsOn).add(shiftByDays, 'day').toISOString(true);
        sign = signAvailable({ ...availableContract, startsOn });
        CzechWorkCalculator.workShiftInContractOrThrow(workShift, sign);
      }

      // final check, overlaps are not allowed
      const conflictingSignedDpcs = (await this.contracts.listForInterval(sign.startsOn, sign.expiresOn, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOPracovniCinnosti] }))
        .filter((contract) => iscoConflict(contract.iscoPrefixes, sign!.iscoPrefixes))
        .filter((contract) => contract.id !== shorten?.id);
      CzechWorkCalculator.contractsValidOrThrow(conflictingSignedDpcs);
      if (conflictingSignedDpcs.length > 0) {
        // TD-2448: DPČ/HPP overlap is allowed for now
        flags.push(WorkShiftCheckFlags.CONTRACTS_OVERLAP);
        // issues.push(new WorkShiftCheckIssue(
        //   WorkShiftCheckFlags.CONTRACTS_OVERLAP,
        //   "Není podepsaná HPP pro danou směnu, ale novou nelze podepsat, protože by se překryla s existující DPČ se stejným zaměstnavatelem na stejnou pozici.",
        //   `[${employee.id}][${workShift.offerId}][${conflictingSignedDpcs.map((con) => con.id).join(",")}]`,
        // ));
      }
      const conflictingSignedHpps = await this.contracts.listForInterval(sign.startsOn, sign.expiresOn, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.HlavniPracovniPomer] });
      CzechWorkCalculator.contractsValidOrThrow(conflictingSignedHpps);
      if (conflictingSignedHpps.length > 0) {
        issues.push(new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACTS_OVERLAP,
          "Není podepsaná HPP pro danou směnu, ale novou nelze podepsat, protože by se překryla s existující HPP se stejným zaměstnavatelem.",
          `[${employee.id}][${workShift.offerId}][${conflictingSignedHpps.map((con) => con.id).join(",")}]`,
        ));
      }
    }
    const resultingContract: ContractTimes = (signedContract ?? sign)!;

    // Smlouvy na HPP aktuálně nebudeme omezovat na odpracované hodiny ani výši výdělku.
    const limits = undefined;

    // working on DPP after DPČ/HPP work has started would be considered tax evasion
    const overlappingDpps = (await this.contracts.listForInterval(resultingContract.startsOn, resultingContract.expiresOn, ContractValidityType.Final, { employeeId: employee.id, employerId: workShift.employerId, types: [CzechContractType.DohodaOProvedeniPrace] }))
      .filter((contract) => iscoMatch(contract.iscoPrefixes, workShift.iscoCode));
    CzechWorkCalculator.contractsValidOrThrow(overlappingDpps);
    const shiftsForDppsAfter = await this.shifts.listForInterval(workShift.startTime, resultingContract.expiresOn, IntervalMatch.RecordBeginsInRequested, { contractIds: overlappingDpps.map((contract) => contract.id) });
    CzechWorkCalculator.workShiftsValidOrThrow(shiftsForDppsAfter);
    if (shiftsForDppsAfter.length > 0) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.CONTRACTS_DOWNGRADE,
        "Již jsou přihlášené pozdější směny na DPP se stejným zaměstnavatelem na stejnou pozici v době platnosti této HPP.",
        `[${employee.id}][${workShift.offerId}][${shiftsForDppsAfter.map((sh) => sh.workId).join(",")}]`,
      ));
    }

    if (!allowSignUp) {
      issues.push(new WorkShiftCheckIssue(
        WorkShiftCheckFlags.CONTRACT_NOT_ALLOWED,
        "Pracovníkovi nebyla zatím schválena práce na HPP.",
        `[${employee.id}][${workShift.offerId}][${employee.id}]`,
      ));
    }

    splitIgnoredIssues(issues, flags, ignoreIssues);
    if (issues.length > 0) {
      return {
        canSignUp: false,
        signedContract,
        issues,
        flags,
        limits,
      };
    }

    return {
      canSignUp: true,
      signedContract,
      makeContractChanges: sign ? { sign, shorten } : undefined,
      issues,
      flags,
      limits,
    };
  }

  public static matchContract(
    employee: Employee,
    workShift: WorkOffer,
    availableContract: CzechAvailableContract,
    signedContracts: CzechContract[],
    extraChecks: ContractCheck[],
    checkOverlap: boolean,
    issues: WorkShiftCheckIssue[],
    flags: WorkShiftCheckFlags[],
    ignoreIssues: WorkShiftCheckFlags[],
  ): CzechContract|undefined {
    // prepend universal checks
    const checks: ContractCheck[] = [
      {
        predicate: (contract) => iscoMatch(contract.iscoPrefixes, workShift.iscoCode),
        flag: WorkShiftCheckFlags.CONTRACTS_MISMATCH,
        description: "Smlouva byla podepsána pro jinou pozici.",
        extra: (contract) => `[${employee.id}][${workShift.iscoCode}][${contract.iscoPrefixes.join(",")}]`,
      },
      {
        predicate: (contract) => contract.templateId === availableContract.templateId,
        flag: WorkShiftCheckFlags.CONTRACTS_MISMATCH,
        description: "Smlouva byla podepsána s jiným zněním, než je vyžadováno pro tuto směnu.",
        extra: (contract) => `[${employee.id}][${availableContract.templateId}][${contract.templateId}]`,
      },
      {
        predicate: (contract) => fixedValuesMatch(contract.fixedValues, availableContract.fixedValues).mismatching === 0,
        flag: WorkShiftCheckFlags.CONTRACTS_MISMATCH,
        description: "Smlouva byla podepsána s jinými údaji, než je vyžadováno pro tuto směnu.",
        extra: (contract) => `[${employee.id}][${JSON.stringify(availableContract.fixedValues)}][${JSON.stringify(contract.fixedValues)}]`,
      },
      ...extraChecks,
    ];

    let contract: CzechContract|undefined;

    // look for fixed contract first
    if (availableContract.fixedContractId) {
      contract = signedContracts.find((contract) => contract.id === availableContract.fixedContractId);
      if (!contract) {
        const issue = new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACT_NOT_FOUND,
          "Vyžádaná podepsaná smlouva nebyla nalezena.",
          `[${employee.id}][${workShift.offerId}][${availableContract.fixedContractId}]`,
        );
        if (ignoreIssues.includes(issue.flag)) {
          flags.push(issue.flag);
          // continue looking at other signed contracts
        } else {
          issues.push(issue);
          // nothing else to do
          return undefined;
        }
      }
    }

    // if no fixed contract was requested
    // or fixed contract was not found but the issue is ignored
    if (!contract) {
      const contracts = signedContracts.filter((contract) => {
        // filter by non-ignored checks
        for (const check of checks) {
          if (!ignoreIssues.includes(check.flag) && !check.predicate(contract)) {
            return false;
          }
        }
        return true;
      }).sort(combineSorters([
        // individual checks in order of priority
        ...checks.map(({ predicate }) => preferenceSorter(predicate)),
        // from most matching fixed values to least
        (a, b) => fixedValuesMatch(b.fixedValues, availableContract.fixedValues).matching - fixedValuesMatch(a.fixedValues, availableContract.fixedValues).matching,
        // sign time from newest to oldest as last resort
        (a, b) => mom(b.signedOn).diff(mom(a.signedOn)),
      ]));

      // check for overlap
      if (checkOverlap && contracts.length > 1) {
        const issue = new WorkShiftCheckIssue(
          WorkShiftCheckFlags.CONTRACTS_OVERLAP,
          "Existuje více odpovídajících smluv pro danou směnu.",
          `[${employee.id}][${workShift.offerId}][${contracts.map((con) => con.id).join(",")}]`,
        );
        if (ignoreIssues.includes(issue.flag)) {
          flags.push(issue.flag);
        } else {
          issues.push(issue);
        }
      }

      // the first contract, if any, is our result
      ([contract] = contracts);
    }

    if (contract) {
      // run checks
      for (const check of checks) {
        if (!check.predicate(contract)) {
          if (ignoreIssues.includes(check.flag)) {
            flags.push(check.flag);
          } else {
            issues.push(new WorkShiftCheckIssue(
              check.flag,
              check.description,
              check.extra(contract),
            ));
          }
        }
      }
    }

    return contract;
  }

  /**
   * Určí vyvažovací období DPČ podle evidovaných hodin (odpracovaných i plánovaných).
   */
  public async computeDpcDates(
    dpc: CzechContract,
  ): Promise<CzechContract & {
    /**
     * Poslední den vyvažovacího období spočteného na základě evidovaných hodin, aby bylo dodrženo v průměru maximum 20 hod. za týden.
     * Aplikuje se výlučně mezi DPČ.
     * Počítá se v celých týdnech, minimum 1 týden.
     * Čas těsně před půlnocí v časové zóně Europe/Prague.
     * ISO 8601 včetně časové zóny.
     */
    dpcEffectiveValidityTill: string;
    /**
     * Poslední den platnosti smlouvy včetně potenciálního pozdějšího zkrácení a potenciálního vyvažovacího období.
     * Aplikuje se výlučně mezi DPČ.
     * Počítá se v celých týdnech, minimum 1 týden.
     * Čas těsně před půlnocí v časové zóně Europe/Prague.
     * ISO 8601 včetně časové zóny.
     */
    dpcEffectiveEndsOn: string;
  }> {
    const tzStartsOn = mom(dpc.startsOn);
    const tzEndsOn = mom(dpc.endsOn);
    const tzExpiresOn = mom(dpc.expiresOn);

    const work = await this.shifts.listForInterval(dpc.startsOn, dpc.endsOn, IntervalMatch.RecordBeginsInRequested, { contractIds: [dpc.id] });
    const netMinutesWorked = sum(work.map(CzechWorkCalculator.calculateForWorkShift).map((calc) => calc.workTimeTotalNetMinutes));
    const days = Math.max(1, Math.ceil(netMinutesWorked / 60 / 20)) * 7;
    const dpcEffectiveValidityTill = tzStartsOn.clone().add(days - 1, 'days').endOf('day').toISOString(true);
    const dpcEffectiveEndsOn = moment.max(tzEndsOn, mom(dpcEffectiveValidityTill)).toISOString(true);
    if (tzExpiresOn.isBefore(mom(dpcEffectiveValidityTill))) {
      throw new InvalidDateIntervalError(`Effective DPČ dates for Contract ${dpc.id}`, dpcEffectiveValidityTill, dpc.expiresOn);
    }
    return {
      ...dpc,
      dpcEffectiveValidityTill,
      dpcEffectiveEndsOn,
    };
  }

  /**
   * Zjistí, do jakého výplatního období směna spadá.
   */
  public static getPayIntervalForWorkShift(workShift: WorkOffer): PayPeriodInterval {
    return {
      period: PayPeriod.Monthly,
      startsOn: mom(workShift.startTime).startOf('month').toISOString(true),
    };
  }

  /**
   * Nespravny docasny vypocet viz. correctMinimalniZdravotniPojisteniProZamestance
   */
  private static incorrectMinimalniZdravotniPojisteniProZamestnance(
    payInterval: PayPeriodInterval,
    totalGrossSalary: number,
    verifiedDocuments: CzechDocument[],
    errors: Error[],
  ): number {
    if (
      [
        CzechDocumentType.PotvrzeniOStudiu,
        CzechDocumentType.VymerStarobnihoDuchodu,
        CzechDocumentType.VymerMaterskeRodicovskeDovolene,
        CzechDocumentType.PotvrzeniJinehoZamestnavateleOOdvoduZdravotnihoPojisteni,
        CzechDocumentType.VymerInvalidnihoDuchodu12Stupne,
        CzechDocumentType.VymerInvalidnihoDuchodu3Stupne,
        CzechDocumentType.PrukazZTPP,
      ].some((document) => CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, document))
    ) {
      return 0;
    }

    const minimumWage = getConst(CzechConstantType.MINIMUM_WAGE, payInterval.startsOn);

    if (CzechDocumentValidator.hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments, payInterval, CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace)) {
      const halfOfMinimumWage = minimumWage / 2;
      if (totalGrossSalary <= halfOfMinimumWage) {
        return 0;
      }
      errors.push(new InvalidDocumentError(CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace.toString(), `Employee has total gross salary over the limit and document of type ${CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace.toString()} is not therefore valid for tax purposes.`));
    }

    const zakladniZdravotniPojisteni = getConst(CzechConstantType.ZAKLADNI_ZDRAVOTNI_POJISTENI, payInterval.startsOn);
    const zdravotniPojisteniPlaceneZamestnavatelem = getConst(CzechConstantType.ZDRAVOTNI_POJISTENI_PLACENE_ZAMESTNAVATELEM, payInterval.startsOn);
    return Math.ceil(minimumWage * (zakladniZdravotniPojisteni + zdravotniPojisteniPlaceneZamestnavatelem) / 100);
  }

  /**
   * Spravny sposob ako by sa malo minimalniZdravotniPojisteniProZamestnance pocitat. Uctovnici pocitaju s tym, ze pocet relevantnych dni sa bude vzdy rovnat poctu dni v mesiaci. Preto to musime ratat zle. viz TD-1331. Ponechane pre pripad, ze si to uctovnici opravia.
   */
  private static correctMinimalniZdravotniPojisteniProZamestnance(
    payInterval: PayPeriodInterval,
    contractStartsOn: string,
    contractEndsOn: string,
    totalGrossSalary: number,
    verifiedDocuments: CzechDocument[],
    errors: Error[],
  ): number {
    const dayCountInPayInterval = getDayCountInPayInterval(payInterval);
    const payIntervalEndsOn = getEndOfPayPeriodInterval(payInterval);
    const contractInPayIntervalStartsOn = mom(contractStartsOn).isBefore(mom(payInterval.startsOn)) ? payInterval.startsOn : contractStartsOn;
    const contractInPayIntervalExpiresOn = mom(contractEndsOn).isAfter(payIntervalEndsOn) ? payIntervalEndsOn.toISOString(true) : contractEndsOn;

    const minimumWage = getConst(CzechConstantType.MINIMUM_WAGE, payInterval.startsOn);
    const halfOfMinimumWage = minimumWage / 2;
    const pouzitPotvrzeniNaUP = totalGrossSalary <= halfOfMinimumWage;
    if (!pouzitPotvrzeniNaUP) {
      if (CzechDocumentValidator.dayCountThatAnyOfTheseDocumentsIsValidInInterval(verifiedDocuments, contractInPayIntervalStartsOn, contractInPayIntervalExpiresOn, [ CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace ]) > 0) {
        errors.push(new InvalidDocumentError(CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace.toString(), `Employee has total gross salary over the limit and document of type ${CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace.toString()} is not therefore valid for tax purposes.`));
      }
    }

    const dayCountWithoutMinimumHealthTax = CzechDocumentValidator.dayCountThatAnyOfTheseDocumentsIsValidInInterval(verifiedDocuments, contractInPayIntervalStartsOn, contractInPayIntervalExpiresOn, [
      CzechDocumentType.PotvrzeniOStudiu,
      CzechDocumentType.VymerStarobnihoDuchodu,
      CzechDocumentType.VymerMaterskeRodicovskeDovolene,
      CzechDocumentType.PotvrzeniJinehoZamestnavateleOOdvoduZdravotnihoPojisteni,
      CzechDocumentType.VymerInvalidnihoDuchodu12Stupne,
      CzechDocumentType.VymerInvalidnihoDuchodu3Stupne,
      CzechDocumentType.PrukazZTPP,
      ...(pouzitPotvrzeniNaUP ? [ CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace ] : []),
    ]);
    const dayCountInContract = mom(contractInPayIntervalExpiresOn).diff(mom(contractInPayIntervalStartsOn), "days") + 1;

    const zakladniZdravotniPojisteni = getConst(CzechConstantType.ZAKLADNI_ZDRAVOTNI_POJISTENI, payInterval.startsOn);
    const zdravotniPojisteniPlaceneZamestnavatelem = getConst(CzechConstantType.ZDRAVOTNI_POJISTENI_PLACENE_ZAMESTNAVATELEM, payInterval.startsOn);
    const base = Math.ceil(minimumWage * (zakladniZdravotniPojisteni + zdravotniPojisteniPlaceneZamestnavatelem) / 100);
    return Math.ceil(base * Math.max(0, dayCountInContract - dayCountWithoutMinimumHealthTax) / dayCountInPayInterval);
  }

  public async calculateAverageEarnings(contract: CzechContract, date: string): Promise<number> {
    const dateMoment = mom(date);
    if (!dateMoment.isBetween(mom(contract.startsOn), mom(contract.endsOn))) {
      throw new DateOutOfDateIntervalError(`Requested date with Contract ${contract.id}`, date, contract.startsOn, contract.endsOn);
    }

    let contracts: CzechContract[];
    if (contract.type === CzechContractType.DohodaOProvedeniPrace) {
      // DPPs during a year are considered as one, but ones from different years are considered separate
      // we're assuming they never cross year boundaries
      contracts = await this.contracts.listForInterval(dateMoment.clone().startOf('year').toISOString(true), dateMoment.clone().endOf('year').toISOString(true), ContractValidityType.Signed, { employeeId: contract.employeeId, employerId: contract.employerId, types: [CzechContractType.DohodaOProvedeniPrace] });
    } else {
      // other contracts are always individual
      contracts = [contract];
    }
    CzechWorkCalculator.contractsValidOrThrow(contracts);
    const combined = combineContracts(`Contract ${contract.id}`, contracts);
    const contractIds = contracts.map(({ id }) => id);

    if (dateMoment.isBefore(mom(combined.startsOn))) {
      return 0;
    }

    const quarters = getQuartersInContract(combined, dateMoment.toISOString(true));
    for (const quarter of quarters) {
      const shifts = await this.shifts.listForInterval(quarter.startsOn.toISOString(true), quarter.endsOn.toISOString(true), IntervalMatch.RecordBeginsInRequested, { status: WorkShiftStatus.Worked, type: WorkShiftType.Regular, contractIds });
      CzechWorkCalculator.workShiftsValidOrThrow(shifts);
      const grossSalaries = shifts.map(CzechWorkCalculator.calculateForWorkShift);
      const totalGrossSalary = Math.ceil(sum(grossSalaries.map((sal) => sal.totalGrossSalary)));
      const workTimeTotalNetMinutes = sum(grossSalaries.map((sal) => sal.workTimeTotalNetMinutes));
      const averageEarnings = Math.round(totalGrossSalary / (workTimeTotalNetMinutes / 60)) || 0;
      if (averageEarnings !== 0) {
        return averageEarnings;
      }
    }
    return 0;
  }
}

function combineContracts(source: string, contracts: CzechContract[]): ContractTimes {
  const combined: ContractTimes = {
    startsOn: moment.min(contracts.map((con) => mom(con.startsOn))).toISOString(true),
    expiresOn: moment.max(contracts.map((con) => mom(con.expiresOn))).toISOString(true),
  };
  if (mom(combined.startsOn).isAfter(mom(combined.expiresOn))) {
    throw new InvalidDateIntervalError(`Combined DPP for ${source}`, combined.startsOn, combined.expiresOn);
  }
  return combined;
}

function preferenceSorter<T>(predicate: (item: T) => boolean) {
  return function(a: T, b: T): number {
    const preferA = predicate(a);
    const preferB = predicate(b);
    if (preferA === preferB) {
      return 0;
    }
    return preferA ? +1 : -1;
  };
}

function combineSorters<T>(sorters: ((a: T, b: T) => number)[]) {
  return function (a: T, b: T): number {
    for (const sorter of sorters) {
      const res = sorter(a, b);
      if (res !== 0) {
        return res;
      }
    }
    return 0;
  };
}

function split<T>(items: T[], predicate: (item: T) => boolean): [passed: T[], rejected: T[]] {
  const passed: T[] = [];
  const rejected: T[] = [];
  for (const item of items) {
    const bucket = predicate(item) ? passed : rejected;
    bucket.push(item);
  }
  return [passed, rejected];
}

function splitIgnoredIssues(
  issues: WorkShiftCheckIssue[],
  flags: WorkShiftCheckFlags[],
  ignoreIssues: WorkShiftCheckFlags[],
): void {
  let ignoredIndex: number;
  while ((ignoredIndex = issues.findIndex(({ flag }) => ignoreIssues.includes(flag))) >= 0) {
    const [ignored] = issues.splice(ignoredIndex, 1);
    flags.push(ignored.flag);
  }
}

function iscoMatch(prefixes: string[], code: string): boolean {
  return prefixes.some((prefix) => code.startsWith(prefix));
}

function iscoConflict(aPrefixes: string[], bPrefixes: string[]): boolean {
  return aPrefixes.some((aPrefix) => iscoMatch(bPrefixes, aPrefix))
    || bPrefixes.some((bPrefix) => iscoMatch(aPrefixes, bPrefix));
}

function fixedValuesMatch(
  contractValues: Record<string, string>,
  workValues: Record<string, string>,
): { matching: number; missing: number; mismatching: number } {
  const result = {
    matching: 0,
    missing: 0,
    mismatching: 0,
  };
  for (const [key, workValue] of Object.entries(workValues)) {
    const contractValue = contractValues[key];
    if (contractValue === undefined) {
      result.missing++;
    } else if (contractValue !== workValue) {
      result.mismatching++;
    } else {
      result.matching++;
    }
  }
  return result;
}

/**
 * Vrací všechna čtvrtletí od zadaného datumu (nebo datumu konce smlouvy) pozpátku do datumu začátku smlouvy.
 * Poslední čtvrtletí je čtvrtletí stávající.
 */
function getQuartersInContract(contract: ContractTimes, date?: string): Quarter[] {
  const momentDate = mom(date ?? contract.startsOn);
  const startOfContract = mom(contract.startsOn);
  if (momentDate.isBefore(startOfContract)) {
    return [];
  }
  momentDate.startOf("quarter");
  const currentQuarter: Quarter = {
    startsOn: momentDate.isAfter(startOfContract) ? momentDate.clone() : startOfContract,
    endsOn: momentDate.clone().endOf("quarter"),
  };
  const quarters: Quarter[] = [];
  while (momentDate.isAfter(startOfContract)) {
    momentDate.subtract(1, "quarter");
    const startsOn = momentDate.isAfter(startOfContract) ? momentDate.clone() : startOfContract;
    const quarter: Quarter = {
      startsOn,
      endsOn: startsOn.clone().endOf("quarter"),
    };
    quarters.push(quarter);
  }
  quarters.push(currentQuarter);
  return quarters;
}

/**
 * Vrací, zda je den sobota.
 * @param date
 * @returns boolean
 * @private
 */
function isDaySaturday(date: Moment): boolean {
  const dayOfWeek = date.day();
  return dayOfWeek === 6;
}

/**
 * Vrací, zda je den neděle.
 * @param date
 * @returns boolean
 * @private
 */
function isDaySunday(date: Moment): boolean {
  const dayOfWeek = date.day();
  return dayOfWeek === 0;
}

/**
 * Vrací, kolik minut směny proběhlo v noci.
 * @param workShift
 */
function calculateWorkShiftNightTimeFrameDuration(workShift: WorkOffer): number {
  const nightWorkShiftStartingHour = 22;
  const nightWorkShiftEndHour = 6;
  const nightTimes = [
    {
      from: mom(workShift.startTime).startOf('day').subtract(1, 'day').hours(nightWorkShiftStartingHour),
      to: mom(workShift.startTime).startOf('day').hours(nightWorkShiftEndHour),
    },
    {
      from: mom(workShift.startTime).startOf('day').hours(nightWorkShiftStartingHour),
      to: mom(workShift.startTime).startOf('day').add(1, 'day').hours(nightWorkShiftEndHour),
    },
  ];

  let nightMinutes = 0;
  for (const { from, to } of nightTimes) {
    if (doTimeRangesOverlap(mom(workShift.startTime), mom(workShift.finishTime), from, to)) {
      nightMinutes += calculateTotalShiftDurationExcludingBreaks(workShift, from, to);
    }
  }
  return nightMinutes;
}

function isWorkShiftDuringNight(workShift: WorkOffer): boolean {
  return calculateWorkShiftNightTimeFrameDuration(workShift) > 0;
}

/**
 * Vrací, kolik minut směny proběhlo během výjimečného dne (svátek/víkend).
 */
function calculateWorkShiftSpecialTimeFrameDuration(workShift: WorkOffer, specialDayFunction: (date: Moment) => boolean): number {
  const workshiftStartTime = mom(workShift.startTime);
  const workshiftFinishTime = mom(workShift.finishTime);

  if (isWorkShiftInSingleDay(workShift)) {
    if (specialDayFunction(workshiftStartTime)) {
      return calculateTotalShiftDurationExcludingBreaks(workShift);
    } else {
      return 0;
    }
  } else {
    const startDayIsSpecial = specialDayFunction(workshiftStartTime);
    const finishDayIsSpecial = specialDayFunction(workshiftFinishTime);

    if (startDayIsSpecial && finishDayIsSpecial) {
      return calculateTotalShiftDurationExcludingBreaks(workShift);
    }

    if (!startDayIsSpecial && !finishDayIsSpecial) {
      return 0;
    }

    const midnight = mom(workShift.finishTime).startOf('day');

    const startDateSpecialDayDuration = startDayIsSpecial ? midnight.diff(workshiftStartTime, 'minute', true) : 0;
    const finishDateSpecialDayDuration = finishDayIsSpecial ? workshiftFinishTime.diff(midnight, 'minute', true) : 0;

    const workBreakDurationDuringSpecialDay = workShift.workBreaks.reduce((durationInMinutes: number, workBreak: WorkBreak) => {
      const startsAt = mom(workBreak.startsAt);
      const endsAt = mom(workBreak.endsAt);

      if (startDayIsSpecial && endsAt.isSameOrBefore(midnight) || finishDayIsSpecial && startsAt.isSameOrAfter(midnight)) {
        return durationInMinutes + workBreak.durationInMinutes;
      }

      if (startsAt.isBefore(midnight) && endsAt.isAfter(midnight)) {
        return durationInMinutes
          + (startDayIsSpecial ? midnight.diff(startsAt, "minutes") : 0)
          + (finishDayIsSpecial ? endsAt.diff(midnight, "minutes") : 0);
      }

      return durationInMinutes;
    }, 0);

    return startDateSpecialDayDuration + finishDateSpecialDayDuration - workBreakDurationDuringSpecialDay;
  }
}

function isWorkShiftDuringSpecialDay(workShift: WorkOffer, specialDayFunction: (date: Moment) => boolean): boolean {
  return calculateWorkShiftSpecialTimeFrameDuration(workShift, specialDayFunction) > 0;
}

function isWorkShiftInSingleDay(workShift: WorkOffer): boolean {
  return mom(workShift.startTime).isSame(mom(workShift.finishTime), 'day');
}

function doTimeRangesOverlap(range1Start: Moment, range1End: Moment, range2Start: Moment, range2End: Moment, minimalGapDurationInMinutes = 0): boolean {
  if (minimalGapDurationInMinutes) {
    range2End = range2End.clone().add(minimalGapDurationInMinutes, 'minute');
    range1End = range1End.clone().add(minimalGapDurationInMinutes, 'minute');
  }
  return range1End > range2Start && range2End > range1Start;
}

function getDayCountInPayInterval(payInterval: PayPeriodInterval): number {
  if (payInterval.period === PayPeriod.Monthly) {
    return mom(payInterval.startsOn).daysInMonth();
  }
  throw new NotImplementedError();
}

function getEndOfPayPeriodInterval(payInterval: PayPeriodInterval): Moment {
  if (payInterval.period === PayPeriod.Monthly) {
    return mom(payInterval.startsOn).endOf('month');
  }
  throw new NotImplementedError(payInterval.period);
}

/**
 * Vrací délku směny včetně přestávek v minutách.
 * @param workShift
 * @private
 */
function calculateTotalShiftDurationIncludingBreaks(workShift: WorkOffer): number {
  const workShiftStartTime = mom(workShift.startTime);
  const workShiftFinishTime = mom(workShift.finishTime);
  return workShiftFinishTime.diff(workShiftStartTime, 'minute', true);
}

/**
 * Vrací reálně odpracovanou dobu na směně v minutách. Při uvedení parametrů `startTime` a/nebo `finishTime` se vypočítá jen odpracovaná doba části směny ohraničené zadanými datumy.
 * @param workShift Směna, pro kterou se odpracovaná doba počítá.
 * @param startTime Datum od kterého se má délka směny počítat.
 * @param finishTime Datum do kterého se má délka směny počítat.
 * @returns
 * @private
 */
function calculateTotalShiftDurationExcludingBreaks(workShift: WorkOffer, startTime?: Moment, finishTime?: Moment): number {
  const workShiftStartTime = mom(workShift.startTime);
  const workShiftFinishTime = mom(workShift.finishTime);
  startTime = startTime && startTime.isSameOrAfter(mom(workShift.startTime)) ? startTime : workShiftStartTime;
  finishTime = finishTime && finishTime.isSameOrBefore(mom(workShift.finishTime)) ? finishTime : workShiftFinishTime;

  if (startTime.isAfter(finishTime)) {
    throw new InvalidDateIntervalError(`Computed interval`, startTime.toISOString(true), finishTime.toISOString(true));
  }

  const shiftDurationIncludingBreaks = finishTime.diff(startTime, 'minute', true);
  const totalBreakDuration = calculateTotalShiftWorkBreakDuration(workShift, startTime, finishTime);
  return shiftDurationIncludingBreaks - totalBreakDuration;
}

/**
 * Vrací celkový čas přestávek v rámci jedné směny minutách. Při uvedení parametrů `startTime` a/nebo `finishTime` se započítají jen přestávky v části směny ohraničené zadanými datumy.
 * @param workShift Směna, pro kterou se doba přestávek počítá.
 * @param startTime Datum od kterého se má délka přestávek počítat.
 * @param finishTime Datum do kterého se má délka přestávek počítat.
 * @returns
 * @private
 */
function calculateTotalShiftWorkBreakDuration(workShift: WorkOffer, startTime: Moment, finishTime: Moment): number {
  const workShiftStartTime = mom(workShift.startTime);
  const workShiftFinishTime = mom(workShift.finishTime);
  startTime = startTime.isSameOrAfter(workShiftStartTime) ? startTime : workShiftStartTime;
  finishTime = finishTime.isSameOrBefore(workShiftFinishTime) ? finishTime : workShiftFinishTime;

  if (startTime.isAfter(finishTime)) {
    throw new InvalidDateIntervalError(`Computed interval`, startTime.toISOString(true), finishTime.toISOString(true));
  }

  return workShift.workBreaks.reduce((sum: number, workBreak: WorkBreak) => {
    const workBreakStartsAt = mom(workBreak.startsAt);
    const workBreakEndsAt = mom(workBreak.endsAt);
    if (startTime.isSameOrAfter(workBreakEndsAt) || finishTime.isSameOrBefore(workBreakStartsAt)) {
      return sum;
    } else if (startTime.isSameOrBefore(workBreakStartsAt) && finishTime.isSameOrAfter(workBreakEndsAt)) {
      return sum + workBreak.durationInMinutes;
    } else {
      return sum + calculateWorkBreakDuration(workBreak, startTime, finishTime);
    }
  }, 0);
}

/**
 * Vrací délku přestávky (či její části, pokud je poskytnut alespoň jeden z parametrů startTime a finishTime) v minutách.
 * @param workBreak
 * @param startTime
 * @param finishTime
 */
function calculateWorkBreakDuration(workBreak: WorkBreak, startTime: Moment, finishTime: Moment): number {
  const workBreakStartsAt = mom(workBreak.startsAt);
  const workBreakEndsAt = mom(workBreak.endsAt);
  startTime = startTime.isSameOrBefore(workBreakStartsAt) ? workBreakStartsAt : startTime;
  finishTime = finishTime.isSameOrAfter(workBreakEndsAt) ? workBreakEndsAt : finishTime;

  if (workBreakStartsAt.isSame(startTime) && workBreakEndsAt.isSame(finishTime)) {
    return workBreak.durationInMinutes;
  } else {
    return finishTime.diff(startTime, 'minute', true);
  }
}

interface Break {
  from: Moment;
  to: Moment;
}

function getAllBreaks(
  shifts: WorkOffer[],
  startDate: Moment,
  endDate: Moment
): Break[] {
  const sortedShifts = [...shifts].sort(
    (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
  );

  const breakList: Break[] = [];

  for (const shift of sortedShifts) {
    if (!startDate.isSame(mom(shift.startTime))) {
      breakList.push({
        from: startDate,
        to: mom(shift.startTime),
      });
    }
    startDate = mom(shift.finishTime);
  }

  if (!startDate.isSame(endDate)) {
    breakList.push({
      from: startDate,
      to: endDate,
    });
  }

  return breakList;
}

function getLongestBreaks(
  breaks: Break[],
  startDate: Moment,
  endDate: Moment,
  breakCount: 1 | 2
): number[] {
  // We do not care about breaks shorter than 8 hours
  const minimalLengthOfValidBreak = 480;
  const breakLengths = [];
  for (const workBreak of breaks) {
    if (workBreak.from.isBefore(endDate) && workBreak.to.isAfter(startDate)) {
      const lengthInMinutes = moment.min(workBreak.to, endDate).diff(moment.max(workBreak.from, startDate)) / 1000 / 60;
      if (lengthInMinutes >= minimalLengthOfValidBreak) {
        breakLengths.push(lengthInMinutes);
      }
    }
  }
  return breakLengths.sort((a, b) => b - a).slice(0, breakCount);
}

function sum(arr: number[]): number {
  return arr.reduce((sum, num) => sum + num, 0);
}

function structuredClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

function signAvailable(available: CzechAvailableContract): ContractToSign {
  const contract = {
    templateId: available.templateId,
    startsOn: available.startsOn,
    expiresOn: available.computeExpiresOn(available.startsOn),
    iscoPrefixes: available.iscoPrefixes,
  };
  if (mom(contract.startsOn).isAfter(mom(contract.expiresOn))) {
    throw new InvalidDateIntervalError(`Computed contract from Template ${available.templateId}`, contract.startsOn, contract.expiresOn);
  }
  return contract;
}

function addDay(dt: string): string {
  return mom(dt).add(1, 'day').toISOString(true);
}

function subDay(dt: string): string {
  return mom(dt).subtract(1, 'day').toISOString(true);
}
